.c-banner{
	position: relative;
	z-index: 1;

	&__viewport{
		position: relative;
		overflow: hidden;
	}

	&__track{
		display: flex;

		> *{
			flex: 1 0 100%;
			width: 100%;
		}
	}

	&__arrows{
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
		display: none;

		@media (min-width: $md){
			display: flex;
		}
	}

	&__image{
		position: relative;
		height: 0;
		padding-bottom: ratio(1140, 617);

		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}
}

@supports (object-fit: cover){
	.c-banner{
		&__image{
			> *{
				top: 0;
				left: 0;
				height: 100%;
				transform: none;
				object-fit: cover;
			}
		}
	}
}

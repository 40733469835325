blockquote,
q{
    quotes: none;
    font-style: normal;
    position: relative;
    padding: 45px 15px;
    color: $primary;
    background-color: $grey;
    font-family: $header-font-family;
    line-height: 1.2;
    margin-top: 30px;
    margin-bottom: 35px;

    @include css-lock(2.4, 4.8, 560, 1200);

    @media (min-width: $md){
        padding: 70px 30px;
    }

    &:before{
        content: none;
    }

    &:after{
        content: '';
        position: absolute;
        bottom: 0;
        right: 15px;
        width: 60px;
        height: 45px;
        transform: translateY(25%);
        background-image: url('../../images/quote.svg');
        background-size: 100% auto;
        background-repeat: no-repeat;

        @media (min-width: $md){
            width: 100px;
            height: 70px;
            right: 30px;
        }
    }
}

blockquote{
    opacity: 0;
    transform: translateX(-10%);
    transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;

    &.scroll-active{
        opacity: 1;
        transform: none;
    }

    p,
    ul,
    ol{
        &:last-child{
            margin-bottom: 0;
        }
    }
}

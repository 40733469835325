.c-header-user {
    position: relative;
    height: 34px;
    display: none;

    @media (min-width: $md) {
        display: block;
    }

    &__btn {
        position: relative;
        z-index: 1;
        color: $text-color;
        padding: 4px 0;
        transition: color $userInactive $easeOut;

        &-wrap {
            display: flex;
            align-items: center;

            > * + * {
                margin-left: 8px;
            }
        }
    }

    .is-open &__btn {
        color: #fff;
        transition-duration: $userActive;
    }

    &__dropdown {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: transform $userInactive $easeOut,
            opacity $userInactive $easeOut;
        background-color: $grey;
        opacity: 0;
        pointer-events: none;

        a {
            display: block;
            width: 100%;
            color: $primary;
            text-align: center;
        }
    }

    &.is-active &__dropdown {
        pointer-events: auto;
        transform: translateY(110%);
        opacity: 1;
        transition: transform $userActive $easeOut, opacity $userActive $easeOut;
    }

    &__down-icon {
        position: relative;
        width: 16px;
        height: 16px;
        color: $primary;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
        }
    }

    &__icon {
        color: $primary;

        &,
        svg {
            width: 28px;
            height: 28px;
        }
    }

    &__down-icon,
    &__icon {
        transition: color $userInactive $easeOut;
    }

    .is-open &__down-icon,
    .is-open &__icon {
        color: #fff;
        transition-duration: $userActive;
    }
}

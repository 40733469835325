.o-form{
    select,
    textarea,
    input[type="text"],
    input[type="password"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="month"],
    input[type="time"],
    input[type="week"],
    input[type="number"],
    input[type="email"],
    input[type="url"],
    input[type="search"],
    input[type="tel"],
    input[type="color"]{
        display: block;
        width: 100%;
        padding: 16px 15px;
        height: 60px;
        color: $black;
        border: 1px solid $grey;
        background-color: $grey;
        background-image: none;
        font-family: inherit;
        border-radius: 0;
        transition: border-color $userInactive $easeOut, background-color $userInactive $easeOut, color $userInactive $easeOut;
        font-size: $base-font-size;

        @include placeholder(){
            color: $primary;
            font-weight: 400;
            opacity: 1;
        }

        &[disabled],
        &[readonly]{
            cursor: not-allowed;
            border-color: $grey;
            background-color: #fff;
            color: rgba($black, 0.5);
            transition: border-color $userInactive $easeOut, background-color $userInactive $easeOut, color $userInactive $easeOut;
        }

        &:focus{
            transition: border-color $userActive $easeOut, background-color $userActive $easeOut, color $userActive $easeOut;
            border-color: $primary;
            color: $primary;
        }
    }

    .error{
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"]{
            transition: border-color $userActive $easeOut, background-color $userActive $easeOut, color $userActive $easeOut;
            border-color: $red;
            color: $red;
        }
    }

    textarea{
        min-height: 175px;
        min-width: 250px;
        resize: none;
    }

    &--white{
        select,
        textarea,
        input[type="text"],
        input[type="password"],
        input[type="datetime"],
        input[type="datetime-local"],
        input[type="date"],
        input[type="month"],
        input[type="time"],
        input[type="week"],
        input[type="number"],
        input[type="email"],
        input[type="url"],
        input[type="search"],
        input[type="tel"],
        input[type="color"]{
            color: $black;
            border: 1px solid #fff;
            background-color: #fff;
        }
    }
}

.o-table {
    tbody > *:nth-child(even) {
        background-color: $grey;
    }

    td a {
        position: relative;
        z-index: 2;
    }

    &--hide-last-column {
        thead th,
        tbody td {
            &:last-child {
                display: none;

                @media (min-width: $sm) {
                    display: table-cell;
                }
            }
        }
    }

    th {
        padding: 20px;
    }

    td {
        padding: 30px 20px;
    }

    &__btn {
        &-wrap {
            display: flex;
            align-items: center;
        }

        &-icon {
            position: relative;
            width: 10px;
            height: 10px;
            margin-left: 5px;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 24px;
                height: 25px;
                transform: translate(-50%, -50%);
            }
        }

        &:hover &-label {
            text-decoration: underline;
        }

        &--asc &-icon {
            svg {
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }

        &--nosort &-icon {
            display: none;
        }
    }
}

.c-property-slider{
	&__viewport{
		position: relative;
		overflow: hidden;
	}

	&__track{
		display: flex;
	}

	&__main{
		padding-left: $gutter;
		padding-right: $gutter;
		margin-bottom: 30px;
	}

	&__nav{
		@media (max-width: $sm-max){
			display: none;
		}
	}

	&__main &__track{
		> *{
			flex: 1 0 100%;
			width: 100%;
		}
	}

	&__nav &__track{
		> *{
			flex: 1 0 16.666%;
			flex-basis: calc(16.666% - #{$full-gutter});
			width: calc(16.666% - #{$full-gutter});
			margin-left: $gutter;
			margin-right: $gutter;
		}
	}

	&__arrows{
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
	}

	&__image{
		position: relative;
		height: 0;

		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			transform: translate(-50%, -50%);
		}
	}

	&__main &__image{
		padding-bottom: ratio(1140, 617);
	}

	&__nav &__image{
		padding-bottom: ratio(165, 100);
	}

	&__nav &__slide{
		cursor: pointer;

		> *{
			pointer-events: none;
		}
	}
}

@supports (object-fit: cover){
	.c-property-slider{
		&__image{
			> *{
				left: 0;
				top: 0;
				height: 100%;
				transform: none;
				object-fit: cover;
			}
		}
	}
}

.c-news-filter{
    position: relative;
    padding-top: 30px;
    width: calc(100% + #{$full-gutter});
    left: -15px;
    z-index: 5;

    @media (min-width: unit(580 / 16, em)){
        width: 580px;
        transform: translateX(-25%);
    }

    @media (min-width: $md){
        padding-top: 70px;
        z-index: 0;
    }

    &__select{
        position: relative;
        margin: 0 auto;
        max-width: 240px;

        @media (min-width: unit(580 / 16, em)){
            left: 60px;
        }

        &:not(:last-child){
            margin-bottom: 30px;
        }
    }

    &:before{
        content: '';
        position: absolute;
        top: 56%;
        left: 0;
        width: 100%;
        height: calc(100% + 35px);
        transform: translateY(-50%);
        background-color: $primary;
        z-index: -1;

        @media (min-width: unit(580 / 16, em)){
            left: 25%;
            width: 580px;
            transform: translate(-25%, -50%);
        }

        @media (min-width: $md){
            top: 40%;
            height: 750px;
        }
    }
}

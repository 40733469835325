.l-news-intro{
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $md){
        flex-wrap: nowrap;
    }

    > *{
        margin-left: $gutter;
        margin-right: $gutter;

        &:first-child{
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$full-gutter});
            order: 1;

            @media (min-width: $md){
                order: 0;
                flex: 1 1 $fiveColSpace;
                flex-basis: calc(#{$fiveColSpace} - #{$full-gutter});
                width: calc(#{$fiveColSpace} - #{$full-gutter});
            }
        }

        &:last-child{
            @media (min-width: $md){
                flex: 1 1 $sevenColSpace;
                flex-basis: calc(#{$sevenColSpace} - #{$full-gutter});
                width: calc(#{$sevenColSpace} - #{$full-gutter});
            }
        }
    }
}

.l-footer{
	display: flex;
	flex-wrap: wrap;
	padding: 75px $gutter 40px;
	width: 100%;

	@media (min-width: $sm){
		padding: 110px $gutter 40px;
	}

	> *{
		margin-left: $gutter;
		margin-right: $gutter;
	}

	&__twitter,
	&__instagram,
	&__logo,
	&__social{
		flex: 1 1 50%;
		flex-basis: calc(50% - #{$full-gutter});
		width: calc(50% - #{$full-gutter});
	}

	&__twitter,
	&__instagram{
		margin-bottom: 45px;

		@media (min-width: $md){
			margin-bottom: 125px;
		}

		> *{
			height: 100%;
		}
	}

	&__logo,
	&__social{
		margin-bottom: 45px;

		@media (min-width: $md){
			margin-bottom: 90px;
		}
	}

	&__logo{
		a{
			display: block;
		}

		svg{
			fill: #fff;
			height: 50px;
			max-width: 100%;
		}
	}

	&__links{
		flex: 1 1 50%;
		flex-basis: calc(50% - #{$full-gutter});
		width: calc(50% - #{$full-gutter});
		padding-right: 50%;
		margin-bottom: 70px;
	}

	&__location{
		flex: 1 1 100%;
		flex-basis: calc(100% - #{$full-gutter});
		width: calc(100% - #{$full-gutter});
	}
}

@supports (display: grid){
	.l-footer{
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas:
			"logo"
			"twitter"
			"instagram"
			"social"
			"links"
			"location";
		grid-column-gap: 30px;

		@media (min-width: $md){
			grid-template-columns: 1fr 1fr;
			grid-template-areas:
				"twitter instagram"
				"logo social"
				"links ..."
				"location location";
		}

		> *{
			margin: 0;
		}

		&__twitter,
		&__instagram,
		&__logo,
		&__social{
			width: auto;
		}

		&__twitter,
		&__instagram{
			> *{
				height: 100%;
			}
		}

		&__logo,
		&__social{
			margin-bottom: 45px;

			@media (min-width: $md){
				margin-bottom: 90px;
			}
		}

		&__twitter,
		&__instagram{
			margin-bottom: 45px;

			@media (min-width: $md){
				margin-bottom: 125px;
			}
		}

		&__twitter{
			grid-area: twitter;
		}

		&__instagram{
			grid-area: instagram;
		}

		&__logo{
			grid-area: logo;
		}

		&__social{
			grid-area: social;
		}

		&__links{
			padding-right: 0;
			width: auto;
			grid-area: links;
			margin-bottom: 70px;
		}

		&__location{
			width: auto;
			grid-area: location;
		}
	}
}

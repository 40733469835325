button{
	overflow: visible;
	border: 0;
	cursor: pointer;
	background: none;
	-webkit-font-smoothing: inherit;
	letter-spacing: inherit;
	font: inherit;

	> *{
		pointer-events: none;
	}

	&:focus,
	&:active{
		outline: none;
		box-shadow: none;
	}
}

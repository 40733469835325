$choices-selector: 'choices' !default;
$choices-guttering: 24px !default;
$choices-border-radius: 2.5px !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #f9f9f9 !default;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: #fff !default;
$choices-text-color: #333 !default;
$choices-keyline-color: #ddd !default;
$choices-primary-color: #00bcd4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: '../../icons' !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;

.#{$choices-selector}{
    position: relative;
    font-size: $base-font-size;

    &:focus{
        outline: none;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &.is-disabled{
        .#{$choices-selector}__inner,
        .#{$choices-selector}__input{
            background-color: $choices-bg-color-disabled;
            cursor: not-allowed;
            user-select: none;
        }

        .#{$choices-selector}__item{
            cursor: not-allowed;
        }
    }
}

.#{$choices-selector}[data-type*="select-one"]{
    cursor: pointer;

    .#{$choices-selector}__input{
        display: block;
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid $choices-keyline-color;
        background-color: #fff;
        margin: 0;
    }

    .#{$choices-selector}__button{
        background-image: url($choices-button-icon-path + '/cross-inverse.svg');
        padding: 0;
        background-size: 8px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
        margin-right: 25px;
        height: 20px;
        width: 20px;
        border-radius: 10em;
        opacity: 0.5;

        &:hover,
        &:focus{
            opacity: 1;
        }

        &:focus{
            box-shadow: 0 0 0 2px $choices-highlight-color;
        }
    }

    &.is-open:after{
        border-color: transparent transparent $choices-text-color transparent;
        margin-top: -7.5px;
    }
}

.#{$choices-selector}[data-type*="select-multiple"],
.#{$choices-selector}[data-type*="text"]{
    .#{$choices-selector}__inner{
        cursor: text;
    }

    .#{$choices-selector}__button{
        position: relative;
        display: inline-block;
        margin: 0 -$choices-button-offset/2 0 $choices-button-offset;
        padding-left: $choices-button-offset*2;
        border-left: 1px solid darken($choices-primary-color, 10%);
        background-image: url($choices-button-icon-path + '/cross.svg');
        background-size: $choices-button-dimension;
        width: $choices-button-dimension;
        line-height: 1;
        opacity: 0.75;

        &:hover,
        &:focus{
            opacity: 1;
        }
    }
}

.#{$choices-selector}__inner{
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: $grey;
    padding: 16px 15px;
    height: 60px;
    border: 1px solid $grey;
    border-radius: 0;
    font-size: $base-font-size;
    overflow: hidden;
    transition: border-color $userInactive $easeOut, color $userInactive $easeOut;

    .is-focused &,
    .is-open &{
        transition: border-color $userActive $easeOut, color $userActive $easeOut;
        border-color: $primary;
        color: $primary;
    }
}

.#{$choices-selector}--white .#{$choices-selector}__inner{
    background-color: #fff;
    border-color: #fff;
    font-weight: 700;
    color: $primary;
}

.#{$choices-selector}__list{
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.#{$choices-selector}__list--single{
    display: inline-block;
    padding: 4px 16px 4px 4px;
    width: 100%;

    .#{$choices-selector}__item{
        width: 100%;
    }
}

.#{$choices-selector}__list--multiple{
    display: inline;

    .#{$choices-selector}__item{
        display: inline-block;
        vertical-align: middle;
        border-radius: $choices-border-radius-item;
        padding: 4px 10px;
        font-size: $base-font-size;
        font-weight: 500;
        margin-right: 3.75px;
        margin-bottom: 3.75px;
        background-color: $choices-primary-color;
        border: 1px solid darken($choices-primary-color, 5%);
        color: #fff;
        word-break: break-all;

        &[data-deletable]{
            padding-right: 5px;
        }

        &.is-highlighted{
            background-color: darken($choices-primary-color, 5%);
            border: 1px solid darken($choices-primary-color, 10%);
        }

        .is-disabled &{
            background-color: darken($choices-disabled-color, 25%);
            border: 1px solid darken($choices-disabled-color, 35%);
        }
    }
}

.#{$choices-selector}__list--dropdown{
    display: none;
    z-index: 50;
    position: absolute;
    width: 100%;
    background-color: $grey;
    border: 1px solid $primary;
    top: 100%;
    margin-top: -1px;
    overflow: hidden;
    word-break: break-all;

    &.is-active{
        display: block;
    }

    .is-flipped &{
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: -1px;
        border-radius: 0.25rem 0.25rem 0 0;
    }

    .#{$choices-selector}__list{
        position: relative;
        max-height: 300px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
    }

    .#{$choices-selector}__item{
        position: relative;
        padding: 20px 15px;
        height: 60px;
        font-size: $base-font-size;
    }

    .#{$choices-selector}__item--selectable{
        &.is-highlighted{
            background-color: $primary;
            color: #fff;
        }
    }
}

.#{$choices-selector}--white .#{$choices-selector}__list--dropdown{
    background-color: #fff;
}

.#{$choices-selector}__item{
    cursor: default;
}

.#{$choices-selector}__item--selectable{
    cursor: pointer;
}

.#{$choices-selector}__item--disabled{
    cursor: not-allowed;
    user-select: none;
    opacity: 0.5;
}

.#{$choices-selector}__heading{
    font-weight: 600;
    font-size: $base-font-size;
    padding: 10px;
    border-bottom: 1px solid lighten($choices-keyline-color, 10%);
    color: lighten(#333, 30%);
}

.#{$choices-selector}__button{
    text-indent: -9999px;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &:focus{
        outline: none;
    }
}

.#{$choices-selector}__btn{
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

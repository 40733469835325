.c-rents-table {
    max-width: $max-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter;
    padding-right: $gutter;
    font-size: 1.2rem;
    overflow: hidden;

    @media (min-width: $xs) {
        font-size: 1.4rem;
    }

    @media (min-width: $md) {
        font-size: 1.6rem;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $sm) {
            flex-wrap: nowrap;
        }

        > * {
            flex: 1 1 20%;
            width: 20%;

            @media (min-width: $sm) {
                flex: 1 1 16.666%;
                width: 16.666%;
            }
        }
    }

    &__header,
    &__data {
        &:nth-child(odd) {
            background-color: $grey;
        }
    }

    &__dropdown-data {
        &:nth-child(even) {
            background-color: $grey;
        }
    }

    &__data {
        position: relative;
        padding: 25px 0;

        @media (min-width: $sm) {
            padding: 25px 15px;
        }

        &:first-child {
            position: relative;
            background-color: $primary;
            flex: 1 1 100%;
            width: 100%;
            color: #fff;
            padding: 5px 0;

            @media (min-width: $sm) {
                flex: 1 1 16.666%;
                width: 16.666%;
                background-color: $grey;
                color: $text-color;
                padding: 25px 15px;
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                width: 20px;
                height: 100%;
                background-color: $primary;

                @media (min-width: $sm) {
                    display: none;
                }
            }

            &:before {
                right: 100%;
            }

            &:after {
                left: 100%;
            }
        }
    }

    &__header {
        font-weight: 700;
        text-transform: uppercase;
        color: $primary;
        padding: 8px 0;
        font-size: 1.2rem;

        @media (min-width: $sm) {
            font-size: 1.6rem;
            padding: 8px 15px;
        }

        &:first-child {
            display: none;

            @media (min-width: $sm) {
                display: block;
            }
        }
    }

    &__dropdown {
        position: relative;
        margin-left: -$gutter;
        margin-right: -$gutter;
        width: calc(100% + #{$full-gutter});
        flex: 1 1 100%;
        flex-basis: calc(100% + #{$full-gutter});
        padding-left: $gutter;
        padding-right: $gutter;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;

        @media (min-width: $sm) {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        &.is-active {
            height: auto;
            transition: height $userActive $easeOut;
        }

        > * {
            padding: 12px 0;

            @media (min-width: $sm) {
                padding: 20px 15px;
            }

            &:nth-child(2n + 1) {
                flex: 1 1 60%;
                width: 60%;

                @media (min-width: $sm) {
                    flex: 1 1 60.666%;
                    width: 66.666%;
                }
            }

            &:nth-child(2n) {
                flex: 1 1 20%;
                width: 20%;
                margin-right: 20%;

                @media (min-width: $sm) {
                    flex: 0 1 14%;
                    width: 16.666%;
                    margin-right: 16.666%;
                }
            }

            &:nth-child(1),
            ~ *:nth-child(2) {
                padding: 24px 0 12px;

                @media (min-width: $sm) {
                    padding: 40px 15px 20px;
                }
            }

            &:nth-last-child(1),
            ~ *:nth-last-child(2) {
                padding: 12px 0 24px;

                @media (min-width: $sm) {
                    padding: 20px 15px 40px;
                }
            }
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -$gutter;
            width: calc(100% + #{$full-gutter});
            height: 100%;

            @media (min-width: $sm) {
                left: 0;
                width: 100%;
            }
        }

        &:before {
            border-top: 1px solid $primary;
        }

        &:after {
            border-bottom: 1px solid $primary;
            z-index: 1;
        }
    }

    &__btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: left;
        z-index: 1;

        @media (min-width: $sm) {
            padding: 0 15px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% + 1px);
            border: 1px solid $primary;
            border-bottom: 1px solid $grey;
            opacity: 0;
            transition: opacity $userInactive $easeOut;
        }

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 10px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid $primary;
            transform: translateX(-50%);
            transition: transform $userInactive $easeOut;
        }

        &:hover:after {
            opacity: 1;
            transition-duration: $userActive;
        }

        &.is-active:after {
            opacity: 1;
            transition-duration: $userActive;
        }

        &.is-active:before {
            transform: translateX(-50%) rotateX(180deg);
        }
    }
}

@supports (display: grid) {
    .c-rents-table {
        &__row {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));

            @media (min-width: $sm) {
                grid-template-columns: repeat(6, minmax(0, 1fr));
            }

            > * {
                width: auto;
            }
        }

        &__data {
            &:first-child {
                width: auto;
                grid-column: 1 / -1;

                @media (min-width: $sm) {
                    grid-column: span 1;
                }
            }
        }

        &__dropdown {
            grid-column: 1 / -1;
            display: grid;
            grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);

            @media (min-width: $sm) {
                grid-template-columns: minmax(0, 4fr) minmax(0, 2fr);
            }

            > * {
                &:nth-child(2n + 1),
                &:nth-child(2n) {
                    width: auto;
                }

                &:nth-child(2n) {
                    margin-right: 50%;
                }
            }
        }
    }
}

.l-contact{
	display: flex;

	&__brochure,
	&__details{
		margin-left: $gutter;
		margin-right: $gutter;
	}

	&__brochure{
		flex: 1 1 $fourColSpace;
		flex-basis: calc(#{$fourColSpace} - #{$full-gutter});
		width: calc(#{$fourColSpace} - #{$full-gutter});
		padding-left: $oneColSpace;
	}

	&__details{
		flex: 1 1 $fiveColSpace;
		flex-basis: calc(#{$fiveColSpace} - #{$full-gutter});
		width: calc(#{$fiveColSpace} - #{$full-gutter});
		padding-right: $oneColSpace;
	}
}

@supports (display: grid){
	.l-contact{
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: $full-gutter;
		padding-left: $gutter;
		padding-right: $gutter;

		@media (min-width: $md){
			grid-template-columns: repeat($columns, 1fr);
		}

		&__brochure,
		&__details{
			margin: 0;
			width: auto;
		}

		&__brochure{
			padding-left: 0;
			grid-row: 2;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			@media (min-width: $md){
				grid-column: 2 / span 4;
				grid-row: 1;
				display: block;
			}

			> *{
				width: 294px;
			}
		}

		&__details{
			padding-right: 0;
			margin-bottom: 30px;

			@media (min-width: $xxs){
				min-width: 450px;
				width: 60%;
			}

			@media (min-width: $md){
				max-width: none;
				width: auto;
				grid-column: 6 / -2;
				margin-bottom: 0;
			}
		}
	}
}

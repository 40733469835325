.c-news-meta{
	&__title{
		color: $primary;
		text-transform: uppercase;
		font-weight: 700;
	}

	&__share-this{
		display: flex;
		margin-top: 5px;

		a{
			display: inline-flex !important;
			align-items: center;
			justify-content: center;
			width: 26px;
			height: 26px;
			border-radius: 50%;
            background-color: $primary;

            &:before,
            &::after{
                display: none;
            }

			&:not(:last-child){
				margin-right: 20px;
			}
		}

		svg{
			fill: #fff;
			width: 13px;
			height: 13px;
		}
	}
}

.o-form {
    width: 100%;

    &__column {
        display: flex;
        flex-direction: column;

        > * {
            flex: 0 0 auto;
        }
    }

    &__group {
        &--inline {
            display: flex;
        }

        &--bottom {
            flex: 1 0 auto;
            display: flex;

            > * {
                margin-top: 35px;
            }
        }
    }

    &__help {
        &-container {
            display: inline-block;
            margin-top: 5px;
            font-weight: 400 !important;
            color: rgba($black, 0.7);
            margin-bottom: 0 !important;
            transition: color $userInactive $easeOut;
            overflow: hidden;
        }

        &-text {
            display: block;
            transition: transform $userInactive $easeOut;
            transform: translateY(-100%);
        }
    }

    .error &__help-container {
        transition-duration: $userActive;
        color: $red;
    }

    .error &__help-text {
        transition-duration: $userActive;
        transform: none;
    }

    &__btn {
        margin-bottom: 20px;
    }
}

.o-file-upload {
    display: flex;
    align-items: center;

    input {
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    label {
        font-size: 1.6rem;
        font-weight: 600;
    }

    &__output {
        margin-left: 30px;
        cursor: pointer;

        @media (min-width: $xs) {
            margin-left: 60px;
        }
    }

    &--multi &__output {
        margin-left: 15px;
        cursor: pointer;

        @media (min-width: $xs) {
            margin-left: 30px;
        }
    }

    &__output-wrap {
        display: flex;

        > * + * {
            margin-left: 15px;

            @media (min-width: $xs) {
                margin-left: 30px;
            }
        }
    }

    &__preview {
        overflow: hidden;
        position: relative;
        width: 45px;
        height: 45px;
        background-color: $grey;

        @media (min-width: $xxs) {
            width: 60px;
            height: 60px;
        }

        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }

        &-list {
            display: flex;

            > * + * {
                margin-left: 15px;

                @media (min-width: $xs) {
                    margin-left: 30px;
                }
            }
        }
    }

    &__btn {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        background-color: $primary;
        color: #fff;
        stroke: currentColor;

        @media (min-width: $xxs) {
            width: 60px;
            height: 60px;
        }

        svg {
            width: 20px;
            height: 20px;
            margin-bottom: 7px;
            transition: transform $userInactive $easeOut;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 16px;
            left: 50%;
            width: 20px;
            height: 2px;
            background-color: currentColor;
            transform: translateX(-50%);
        }
    }

    label:hover &__btn svg {
        transform: translateY(-40%);
        transition-duration: $userActive;
    }

    &__count {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 3.2rem;
        font-weight: 700;
    }

    &__count.is-active + svg {
        opacity: 0.3;
    }
}

@supports (object-fit: cover) {
    .o-file-upload {
        &__preview > img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}

.c-dashboard {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-left: 8px;
    padding-right: 8px;

    > * {
        margin-left: 7px;
        margin-right: 7px;
        margin-bottom: 14px;
        flex: 1 0 33.333%;
        flex-basis: calc(33.333% - 78px);
        width: calc(33.333% - 78px);
    }
}

@supports (display: grid) {
    .c-dashboard {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 14px;
        padding-left: $gutter;
        padding-right: $gutter;

        @media (min-width: $sm) {
            grid-gap: $full-gutter;
        }

        > * {
            margin: 0;
            width: auto;
        }
    }
}

.h-ratio{
	&--21-7,
	&--16-9,
	&--4-3,
	&--3-2,
	&--1-1{
		position: relative;
		width: 100%;
		height: 0;

		> *{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&--21-7{
		@include ratio(21, 7);
	}

	&--16-9{
		@include ratio(16, 9);
	}

	&--4-3{
		@include ratio(4, 3);
	}

	&--3-2{
		@include ratio(3, 2);
	}

	&--1-1{
		@include ratio(1, 1);
	}

	&--cover{
		object-fit: cover;
	}

	&--contain{
		object-fit: contain;
	}
}

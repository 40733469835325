.c-filter{
	display: flex;
	flex-wrap: wrap;
	background-color: $primary;
	padding: 30px 15px;

	@media (min-width: $xxs){
		padding: 30px;
	}

	@media (min-width: $md){
		padding: 70px 45px;
	}

	> *{
		@media (min-width: $xxs){
			margin-left: $gutter;
			margin-right: $gutter;
		}

		@media (min-width: $md){
			margin: 0;

			&:not(:last-child){
				margin-right: 30px;
			}
		}
	}

	&__select,
	&__input{
		flex: 1 1 100%;
		margin-bottom: 15px;

		@media (min-width: $xxs){
			flex: 1 1 50%;
			flex-basis: calc(50% - #{$full-gutter});
			width: calc(50% - #{$full-gutter});
			margin-bottom: 30px;
		}

		@media (min-width: $md){
			flex: 1 1 215px;
			width: auto;
			margin-bottom: 0;
		}
	}

	&__btn-container{
		flex: 0 0 60px;
		margin-left: auto;
	}
}

@supports (display: grid){
	.c-filter{
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 15px;

		@media (min-width: $xxs){
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
		}

		@media (min-width: $md){
			grid-template-columns: repeat(4, 1fr) 60px;
		}

		> *{
			&:not(:last-child){
				margin-right: 0;
			}
		}

		&__select,
		&__input{
			@media (min-width: $xxs){
				width: auto;
			}
		}

		&__btn-container{
			grid-column: 1 / -1;
			margin-left: auto;

			@media (min-width: $md){
				grid-column: auto;
			}
		}
	}
}

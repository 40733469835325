.o-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    height: 100%;
    padding-bottom: 40px;

    @media (min-width: $sm) {
        justify-content: flex-end;
        padding-bottom: 0;
    }

    &__summary {
        margin-right: 30px;
    }

    &__nav {
        &,
        ul {
            display: flex;
        }

        > * + *,
        li + li {
            margin-left: 10px;
        }

        ul a {
            display: block;
            padding: 0 2px;
        }

        span {
            color: $text-color;
            font-weight: 700;
        }
    }

    &__nav &__direction--disabled {
        font-weight: 400;
        color: $grey;
    }
}

.c-business-listing{
	&__viewport{
		position: relative;
		overflow: hidden;
	}

	&__track{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media (min-width: $lg){
			justify-content: flex-start;
		}

		> *{
			flex: 1 1 (360px - $full-gutter);
			max-width: 360px;
			margin-left: $gutter;
			margin-right: $gutter;
			margin-bottom: 30px;

			@media (min-width: $lg){
				margin-bottom: 0;
			}
		}
	}

	&__slide{
		padding-bottom: 30px;

		@media (min-width: $sm){
			display: flex;
			flex-direction: column;
			padding-bottom: 45px;
		}
	}

	&__image{
		position: relative;
		height: 0;
		background-color: $grey;
		padding-bottom: ratio(360, 250);
		margin-bottom: 15px;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: block;
		}

		&-wrap{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 50%;
			height: 50%;
			transform: translate(-50%, -50%);
		}

		img{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			transform: translate(-50%, -50%);
		}

		&-wrap--tall img{
			width: auto;
			height: 100%;
			max-width: none;
		}
	}

	&__content{
		margin-bottom: 15px;

		p:last-child{
			margin-bottom: 0;
		}
	}

	&__btn{
		margin-top: auto;
	}
}

@supports (object-fit: contain){
	.c-business-listing{
		&__image{
			img{
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: none;
				object-fit: contain;
			}
		}
	}
}

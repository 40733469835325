$cta-padding: 60px;
$halfArrowBtn: 30px;

.c-cta{
    position: relative;
    display: flex;
    padding: $cta-padding / 2;
    text-decoration: none;

    @media (min-width: $xs){
        padding: $cta-padding;
    }

    @media (min-width: $md){
        display: inline-flex;
    }

    &--offset-top-right{
        padding: 120px 0 0;

        @media (min-width: $xs){
            padding: 100px 0 0;
        }

        @media (min-width: $md){
            padding: (90px + $cta-padding) (70px + $cta-padding) $cta-padding $cta-padding;
        }
    }

    &--offset-bottom-right{
        padding: 0 0 120px;

        @media (min-width: $xs){
            padding: 0 0 100px;
        }

        @media (min-width: $md){
            padding: $cta-padding (60px + $cta-padding + $halfArrowBtn) (30px + $cta-padding) $cta-padding;
            margin-right: -$halfArrowBtn;
        }
    }

    &--full-width,
    &--full-width-right{
        display: block;
        padding: 0 0  $cta-padding;

        @media (min-width: $md){
            display: flex;
            padding: 0 0  120px + $cta-padding;
        }
    }

    &--full-width-right{
        justify-content: flex-end;
    }

    &--signpost{
        padding: 0;

        @media (min-width: $md){
            display: flex;
        }

        > button{
            position: relative;
        }

        &:after,
        > button:after{
            content: '';
            position: absolute;
            right: -30px;
            bottom: 29px;
            width: 30px;
            height: 62px;
            display: none;

            @media (min-width: $lg){
                display: block;
            }
        }
    }

    &__overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity $userScrollIn $easeOut;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $grey;
            opacity: 1;
            transition: opacity $userInactive $easeOut;
        }
    }

    .scroll-active &__overlay{
        opacity: 1;
    }

    .scroll-active &:hover &__overlay:before,
    .scroll-active &:focus &__overlay:before{
        transition-duration: $userActive;
        opacity: 0;
    }

    &--full-width &__overlay,
    &--full-width-right &__overlay,
    &--offset &__overlay{
        display: none;
    }

    &__btn{
        position: absolute;
        z-index: 4;
        transform: translateX(-30px);
        opacity: 0;
        transition: transform $userScrollIn $easeOut 300ms, opacity $userScrollIn $easeOut 300ms;
    }

    &:nth-child(2) &__btn{
        transition: transform $userScrollIn $easeOut 450ms, opacity $userScrollIn $easeOut 450ms;
    }

    .scroll-active &__btn{
        transform: none;
        opacity: 1;
    }

    &--signpost .o-square__container,
    &--threes .o-square__container,
    &--full-width .o-square__container{
        @media (max-width: $xxs-max){
            position: relative;
            min-height: 250px;
        }
    }

    &--signpost .o-square,
    &--threes .o-square,
    &--full-width .o-square{
        @media (max-width: $xxs-max){
            &:after{
                display: none;
            }
        }
    }

    &--offset-top-right &__btn{
        top: 65px;
        left: 50%;

        @media (min-width: $md){
            top: calc(50% + 15px);
            right: 40px;
            left: auto;
        }
    }

    &--offset-bottom-right &__btn{
        bottom: 65px;
        left: 50%;

        @media (min-width: $md){
            bottom: 30px;
            right: 0;
            left: auto;
        }
    }

    &__image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &--offset-top-right &__image,
    &--offset-bottom-right &__image{
        height: auto;
        left: 15px;
        z-index: 3;
        max-width: 220px;
        width: 60%;

        @media (min-width: $xxs){
            left: 50%;
            transform: translateX(calc(-100% + 25px));
        }

        @media (min-width: $md){
            z-index: 1;
            left: 0;
            transform: none;
            max-width: none;
        }

        &:after{
            content: '';
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: ratio(360, 330);

            @media (min-width: $md){
                display: none;
            }
        }
    }

    &--offset-top-right &__image{
        top: 0;
        max-width: 220px;
        width: 60%;

        @media (min-width: $md){
            top: 90px;
            max-width: none;
            height: calc(100% - 90px);
            width: calc(100% - 70px);
        }
    }

    &--offset-bottom-right &__image{
        top: auto;
        bottom: 0;

        @media (min-width: $md){
            bottom: auto;
            top: 0;
            height: calc(100% - 30px);
            width: calc(100% - #{60px + $halfArrowBtn});
        }
    }

    &--full-width &__image,
    &--full-width-right &__image{
        position: relative;
        width: 100%;
        height: 250px;
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;

        @media (min-width: $md){
            position: absolute;
            height: 60%;
            height: calc(100% - 120px);
            z-index: 1;
        }

        .scroll-active &{
            opacity: 1;
            transform: none;
        }
    }

    &__content{
        max-width: 450px;
        margin: 0 auto;
        width: 100%;
        z-index: 2;
        transform: translateY(20px);
        opacity: 0;
        transition: transform $userScrollIn $easeOut, opacity $userScrollIn $easeOut;

        @media (min-width: $md){
            max-width: none;
            width: 333px;
            margin: 0;
        }

        > *{
            width: 100%;
            max-width: none;
        }

        .o-square__arrow-btn{
            bottom: -15px;

            @media (min-width: $xxs){
                bottom: 0;
            }
        }
    }

    &--signpost &__content{
        width: 100%;
        max-width: none;
    }

    .scroll-active &__content{
        opacity: 1;
        transform: none;
    }

    &--offset-top-right &__content{
        @media (min-width: $md){
            transform: translate(70px + $cta-padding + 20px, -(90px + $cta-padding + 20px));
        }
    }

    .scroll-active &--offset-top-right &__content{
        @media (min-width: $md){
            transform: translate(70px + $cta-padding, -(90px + $cta-padding));
        }
    }

    &--offset-bottom-right &__content{
        @media (min-width: $md){
            transform: translate(60px + $cta-padding + 20px, 30px + $cta-padding + 20px);
        }
    }

    .scroll-active &--offset-bottom-right &__content{
        @media (min-width: $md){
            transform: translate(60px + $cta-padding, 30px + $cta-padding);
        }
    }

    &--full-width &__content{
        @media (min-width: $xs){
            transform: translateY(-10px);
        }

        @media (min-width: $md){
            transform: translate(38px, 120px + $cta-padding + 20px);
        }
    }

    .scroll-active &--full-width &__content{
        @media (min-width: $md){
            transform: translate(38px, 120px + $cta-padding);
        }
    }

    &--full-width-right &__content{
        @media (min-width: $xs){
            transform: translateY(-10px);
        }

        @media (min-width: $md){
            transform: translate(-38px, 120px + $cta-padding + 20px);
        }
    }

    .scroll-active &--full-width-right &__content{
        @media (min-width: $md){
            transform: translate(-38px, 120px + $cta-padding);
        }
    }

    &:not(div):hover .o-btn__icon,
    &:not(div):focus .o-btn__icon{
        transition-duration: $userActive;
        transform: translateX(40%);
    }

    &:not(div):hover .o-btn__icon--left,
    &:not(div):hover .o-btn__icon--left{
        transition-duration: $userActive;
        transform: translateX(-40%);
    }

    &:not(div):hover .o-btn__icon--up,
    &:not(div):hover .o-btn__icon--up{
        transition-duration: $userActive;
        transform: translateY(-40%);
    }

    &:not(div):hover .o-btn__icon--down,
    &:not(div):hover .o-btn__icon--down{
        transition-duration: $userActive;
        transform: translateY(40%);
    }
}

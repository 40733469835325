ul,
ol{
	margin: 0;
	padding: 0;
	list-style: none;
}

#{$typegraphyClass}{
	ul,
	ol{
		padding: 0;
		margin: 0 0 $type-margin-bottom 2.2em;

		ul,
		ol{
			margin: 0 0 0 1.5em;
		}
	}

	ul,
	ol{
		list-style: none;
	}

	li{
		margin-bottom: 4px;
		line-height: line-height-scale();
	}

	ul li{
		position: relative;

		&:before{
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $primary;
			position: absolute;
			top: 0.85em;
			left: -0.85em;
			transform: translate(-50%, -50%);
		}
	}

	ol{
		counter-reset: orderedList;
	}

	ol li{
		position: relative;
		margin-left: -0.75em;

		&:before{
			counter-increment: orderedList;
			content: counter(orderedList) '.';
			color: $primary;
			margin-right: 0.5em;
		}
	}
}

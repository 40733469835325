.o-form__icon{
	stroke: $primary;
	height: 20px;
	width: 20px;

	&-group{
		position: relative;
	}

	&-wrap{
		position: absolute;
		top: 0;
		right: 25px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		pointer-events: none;
	}
}

.o-form__input-group{
    display: flex;

    input:focus{
        border-color: #fff !important;
    }

    button{
        flex: 0 0 60px;
    }
}

.c-slider-cta{
    opacity: 0;
    transform: translateY(20px);
    transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;

    @media (min-width: $md){
        max-width: 720px;
        flex: 1 0 $eightColSpace;
        flex-basis: calc(#{$eightColSpace} - #{$full-gutter});
        width: calc(#{$eightColSpace} - #{$full-gutter});
    }

    .scroll-active &{
        opacity: 1;
        transform: none;
    }

    &__container{
        width: 100%;

        @media (min-width: $md){
            display: flex;
            padding-bottom: 60px;
            transform: translateX($oneColSpace);
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
        }
    }

    &__viewport{
        position: relative;
        overflow: hidden;
    }

    &__track{
        display: flex;

        > *{
            flex: 1 0 100%;
            width: 100%;
        }
    }

    &__image{
        position: relative;
        height: 0;
        padding-bottom: ratio(720, 468);

        > *{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__square{
        width: 100%;
        margin-top: 30px;

        @media (min-width: $md){
            transform: translateY(60px);
            margin-top: 0;
        }

        &-container{
            position: relative;
            display: flex;
            align-items: flex-end;
            opacity: 0;
            transform: translateY(20px);
            transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn $easeOut 300ms;

            @media (min-width: $md){
                flex: 1 1 $fiveColSpace;
                flex-basis: calc(#{$fiveColSpace} - #{$full-gutter});
                width: calc(#{$fiveColSpace} - #{$full-gutter});
                margin-left: calc(-#{$twoColSpace} + #{$full-gutter});
            }

            .scroll-active &{
                opacity: 1;
                transform: none;
            }
        }
    }

    &__arrows{
        position: absolute;
        bottom: 0;
        right: 100%;
        z-index: 2;
        width: 120px;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut 450ms, transform $userScrollIn $easeOut 450ms;

        @media (max-width: $sm-max){
            display: none;
        }

        .scroll-active &{
            opacity: 1;
            transform: none;
        }
    }
}

@supports (object-fit: cover){
    .c-slider-cta{
        &__image{
            > *{
                top: 0;
                left: 0;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

@supports (display: grid){
    .c-slider-cta{
        width: auto;
        padding: 0;

        @media (min-width: $md){
            grid-column: 2 / span 8;
            grid-row: 1;
        }

        &__container{
            display: grid;
            grid-template-columns: 1fr;
            grid-column-gap: $full-gutter;
            padding: 0 $gutter 0;
            transform: none;

            @media (min-width: $md){
                padding: 0 $gutter 60px;
                grid-template-columns: repeat($columns, 1fr);
            }

            > *{
                margin: 0;
            }
        }

        &__square{
            &-container{
                width: auto;
                margin: 0;

                @media (min-width: $md){
                    grid-column: 8 / -1;
                    grid-row: 1;
                }
            }
        }
    }
}

@mixin ratio($width, $height){
	padding-bottom: ratio($width, $height);
}

@mixin placeholder{
	&::-webkit-input-placeholder{
		@content;
	}

	&:-moz-placeholder{
		@content;
	}

	&::-moz-placeholder{
		@content;
	}

	&:-ms-input-placeholder{
		@content;
	}
}

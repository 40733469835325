.o-accordion{
    box-shadow: inset 0 0 1px 1px $primary;
    background-color: #fff;
    transition: color $userInactive $easeOut, background-color $userInactive $easeOut;
    text-align: left;
    border-radius: 0;
    width: 100%;

    &:hover,
    &:focus{
        background-color: $primary;
        color: #fff;
        transition: color $userActive $easeOut, background-color $userActive $easeOut;
    }

    &--page-width{
        position: relative;
        z-index: 99;
        box-shadow: none;
        border-bottom: 1px solid $primary;
        border-top: 1px solid $primary;

        > *{
            max-width: $max-width;
            margin: 0 auto;
        }
    }

    &__list{
        padding-top: 20px;
        padding-bottom: 25px;

        > *{
            opacity: 0;
            transform: translateY(20px);
            transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;

            &:not(:last-child){
                margin-bottom: 18px;
            }
        }

        &.scroll-active > *{
            opacity: 1;
            transform: none;
            transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn $easeOut 300ms;

            @for $i from 2 through 5{
                $delay: 300ms + 150ms * ($i - 1);

                &:nth-child(#{$i}){
                    transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
                }
            }

            &:nth-child(5) ~ *{
                $delay: 300ms + 150ms * (4);

                transition: opacity $userScrollIn $easeOut $delay, transform $userScrollIn $easeOut $delay;
            }
        }
    }

    &__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        text-align: left;
        font-weight: 700;
        padding: 15px 25px;
        color: $primary;
        text-transform: uppercase;

        @media (min-width: $md){
            padding: 25px 45px;
        }
    }

    &:hover &__title,
    &:focus &__title{
        color: #fff;
    }

    &__icon{
        flex: 0 0 20px;
        margin-left: 25px;
        width: 20px;
        height: 20px;
        stroke: currentColor;
        position: relative;

        svg{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            transform: translate(-50%, -50%);
            transition: transform $userInactive $easeOut, opacity $userInactive $easeOut;

            &:first-child{
                transition-delay: $userInactive;
                opacity: 1;
            }

            &:last-child{
                opacity: 0;
                transform: translate(-50%, calc(-50% + 10px));
            }
        }
    }

    &:hover &__icon,
    &:focus &__icon,
    &.active &__icon{
        svg{
            transition-duration: $userActive;

            &:first-child{
                transition-delay: 0ms;
                opacity: 0;
                transform: translate(-50%, calc(-50% + 10px));
            }

            &:last-child{
                transition-delay: $userInactive;
                opacity: 1;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__container{
        height: 0;
        will-change: height;
        overflow: hidden;
        transition: height $userInactive $easeOut;
    }

    &.active &__container{
        height: auto;
        transition: height $userActive $easeOut;
    }

    &__content{
        padding: 10px 25px 30px;

        @media (min-width: $md){
            padding: 20px 45px 30px;
        }

        > *:last-child{
            margin-bottom: 0;
        }
    }
}

//====================
//	Font Families
//====================

@font-face{
	font-family: 'Caslon';
	src: url('../fonts/ACaslonPro-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face{
	font-family: 'Caslon';
	src: url('../fonts/ACaslonPro-Semibold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face{
	font-family: 'Caslon';
	src: url('../fonts/ACaslonPro-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=ce7c998c-fbd4-4701-b708-3c8b02886764&fontids=1475508,1475556");

@font-face{
	font-family: "Avenir web";
	src:
		url("../fonts/1475508/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),
		url("../fonts/1475508/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),
		url("../fonts/1475508/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face{
	font-family: "Avenir web";
	src:
		url("../fonts/1475556/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),
		url("../fonts/1475556/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),
		url("../fonts/1475556/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

// colours
//===============
.h{
	&-primary{
		color: $primary !important;
	}

	&-white{
		color: #fff !important;
	}

	&-grey{
		color: $grey !important;
	}

	&-red{
		color: $red !important;
	}

	&-black{
		color: $black !important;
	}
}

.radio,
.checkbox {
    padding-bottom: 25px;

    @media (min-width: $sm) {
        padding-top: 35px;
    }

    &--no-top-space {
        @media (min-width: $sm) {
            padding-top: 0;
        }
    }

    & + & {
        margin-left: 28px;
    }

    > label {
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        margin-bottom: 0;

        * {
            pointer-events: none;
        }
    }

    input {
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    span {
        display: inline-block;
        margin-left: 5px;
        flex: 1 1 100%;
        width: 100%;
        white-space: normal;
    }

    input {
        + label {
            flex: 0 0 60px;
            position: relative;
            display: inline-block;
            width: 60px;
            height: 60px;
            background-color: $grey;
            border: $border-size solid $grey;
            margin-bottom: 0;
            margin-right: 0.5em;
            max-width: none;
        }
    }
}

.radio {
    input {
        + label {
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                background-color: transparent;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:checked + label {
            &:after {
                background: $primary;
            }
        }
    }
}

.checkbox {
    input {
        + label {
            position: relative;

            svg {
                color: $primary;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 55px;
                height: 55px;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity $userInactive $easeOut;
            }
        }

        &:checked + label svg {
            opacity: 1;
            transition-duration: $userActive;
        }
    }
}

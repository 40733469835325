figure{
	> *:first-child{
		margin-bottom: 25px;
	}

	figcaption{
		font-size: 1.2rem;
		line-height: 1.6;
		max-width: 200px;
	}
}

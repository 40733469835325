//====================
// Typography
//====================

$typegraphyClass: ".typography";

// Font Families
//====================
$font-family: "Avenir", "Avenir web", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$header-font-family: "Caslon", $font-family;

$base-font-size: 1.4rem;
$header-line-height: 1.2;
$p-line-height: 1.43;

// weights
//====================
$font-weight: 400;
$strong-weight: 600;
$header-weight: 400;

// Units
//====================
$base-font-size-unit: rem;

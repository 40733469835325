a{
	background-color: transparent;
	transition: color $userInactive $easeOut;
	color: $text-color;

	&:hover,
	&:focus{
		color: $primary;
		transition: color $userActive $easeOut;
	}
}

.o-deco{
    position: relative;
    overflow: hidden;

    @media (min-width: $lg){
        overflow: visible;
    }

    &--right-bar,
    &--right-bar-top{
        &:after{
            content: '';
            position: absolute;
            top: 50%;
            left: 100%;
            width: 50vw;
            height: 355px;
            background-color: $primary;
            transform: translate(105px, -50%);
            margin-top: 45px;
            opacity: 0;
            transition: transform $userInactive $easeOut, opacity $userInactive $easeOut;
        }

        &.scroll-active:after{
            transition: transform 750ms $easeOut, opacity 750ms $easeOut;
            transform: translate(45px, -50%);
            transition-delay: 425ms;
            opacity: 1;
        }
    }

    &--right-bar-top{
        &:after{
            margin-top: 0;
            top: 0;
            transform: translateX(105px);
        }

        &.scroll-active:after{
            transform: translateX(45px);
        }
    }

    &--bottom-left-square{
        &:after{
            content: '';
            position: absolute;
            bottom: 30px;
            right: calc(100% + 60px);
            width: 375px;
            height: 355px;
            background-color: $primary;
            opacity: 0;
            transform: translateX(-10%);
            transition: opacity $userScrollIn $easeOut 600ms, transform $userScrollIn $easeOut 600ms;
        }

        &.scroll-active:after{
            opacity: 1;
            transform: none;
        }
    }

    &--property-square{
        color: #fff;

        &:after{
            content: '';
            position: absolute;
            top: -15px;
            left: -15px;
            min-width: 425px;
            width: 60%;
            height: calc(100% + 15px);
            z-index: -1;
            background-color: $primary;

            @media (min-width: $md){
                top: auto;
                bottom: 0;
                left: -30px;
                width: 580px;
                height: 750px;
            }
        }

        ul li:before{
            background-color: currentColor;
        }
    }
}

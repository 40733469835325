.o-card {
    position: relative;
    display: flex;
    border: 1px solid $primary;
    padding: 10px;
    text-decoration: none;
    height: 125px;

    @media (min-width: $sm) {
        padding: 30px;
        height: 165px;
    }

    &--hide-md {
        display: none;

        @media (min-width: $md) {
            display: flex;
        }
    }

    &--btn,
    &--tall {
        flex-direction: column;
        justify-content: space-between;
    }

    &--tall {
        @media (min-width: $md) {
            height: auto;
            min-height: 360px;
        }
    }

    &--table-tall,
    &--zebra-table {
        height: auto;
    }

    &--media {
        border: 0;
        padding: 0;

        > * {
            flex: 1 1 50%;
            flex-basis: calc(50% - 15px);

            &:first-child {
                margin-right: 15px;
            }

            &:last-child {
                margin-left: 15px;
            }
        }
    }

    &--btn,
    &--download-btn,
    &--contact-btn {
        border: 0;
        background-color: $primary;
        color: #fff;

        &:hover {
            color: #fff;
        }

        h2 {
            color: currentColor;
        }
    }

    &--download-btn,
    &--contact-btn {
        @media (min-width: $md) {
            background-color: $grey;
            color: $primary;

            &:hover {
                color: $primary;
            }
        }
    }

    &--stat {
        @media (min-width: $sm) {
            padding: 22px 30px;
        }
    }

    &--no-padding {
        padding: 0;
    }

    &--content {
        transition: background-color $userInactive $easeOut;

        &:hover {
            background-color: $grey;
            transition-duration: $userActive;

            @media (min-width: $sm) {
                background-color: #fff;
            }
        }
    }

    &__title {
        text-transform: uppercase;
    }

    &--stat &__title {
        margin-bottom: 5px;
    }

    &__btn {
        margin-top: $gutter;
        align-self: flex-end;
        z-index: 1;
    }

    &--btn &__btn,
    &--download-btn &__btn,
    &--contact-btn &__btn {
        margin-top: 0;
        position: absolute;
        bottom: 10px;
        right: 15px;
    }

    &--download-btn &__btn {
        @media (min-width: $md) {
            bottom: 30px;
            right: 30px;
        }
    }

    &--contact-btn &__btn {
        @media (min-width: $md) {
            bottom: 30px;
            right: auto;
            left: 30px;
        }
    }

    &--tall &__btn {
        display: none;

        @media (min-width: $sm) {
            display: block;
        }
    }

    &__content {
        width: 100%;
        z-index: 1;

        h2 {
            font-size: 1.2rem;

            @media (min-width: $sm) {
                font-size: 1.4rem;
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__content > *:not(&__title) {
        display: none;

        @media (min-width: $md) {
            display: block;
        }
    }

    &--table-tall &__content > *:not(&__title) {
        display: block;
    }

    &--no-padding &__content {
        padding: 30px;

        &:not(:last-child):first-child {
            padding-bottom: 15px;
        }
    }

    &--stat &__content {
        text-align: center;
    }

    &--download-btn &__content {
        @media (min-width: $md) {
            margin-top: 60px;
        }
    }

    &--zebra-table &__content {
        padding: 30px;
    }

    &__stat {
        font-size: 6rem;
        line-height: 1.2;
        color: $primary;
        margin-bottom: 4px;
    }

    &__media-wrap {
        position: relative;
        height: 165px;
        width: 165px;

        > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        > img {
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;

        @media (min-width: $md) {
            display: block;
        }
    }

    &--download-btn &__background {
        background-repeat: no-repeat;
        background-size: 300px auto;
        background-position: 0 -50px;
    }

    &--contact-btn &__background {
        background-repeat: no-repeat;
        background-size: 210px auto;
        background-position: right bottom;
    }

    &__table {
        width: 100%;
        padding: 10px 0;

        table {
            margin-bottom: 0;
        }

        th {
            font-weight: 700;
            color: $primary;
            text-transform: uppercase;
        }

        th,
        td {
            padding: 7px 30px;
        }

        td {
            text-align: right;
            word-break: break-all;
        }
    }

    &--zebra-table &__table {
        padding: 0;

        &-data {
            padding: 20px 30px;
            line-height: 1.35;

            &:nth-child(odd) {
                background-color: $grey;
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:not(div):hover .o-btn__icon,
    &:not(div):focus .o-btn__icon {
        transition-duration: $userActive;
        transform: translateX(40%);
    }

    &:not(div):hover .o-btn__icon--left,
    &:not(div):hover .o-btn__icon--left {
        transition-duration: $userActive;
        transform: translateX(-40%);
    }

    &:not(div):hover .o-btn__icon--up,
    &:not(div):hover .o-btn__icon--up {
        transition-duration: $userActive;
        transform: translateY(-40%);
    }

    &:not(div):hover .o-btn__icon--down,
    &:not(div):hover .o-btn__icon--down {
        transition-duration: $userActive;
        transform: translateY(40%);
    }
}

@supports (object-fit: cover) {
    .o-card {
        &__media-wrap {
            > img {
                top: 0;
                left: 0;
                height: 100%;
                transform: none;
                object-fit: cover;
            }
        }
    }
}

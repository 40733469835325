*,
*::before,
*::after{
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
button,
figure{
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
blockquote,
menu,
nav,
section,
summary{
	display: block;
}

img,
object,
embed,
video{
	display: block;
	max-width: 100%;
	border: 0;
}

video{
	height: auto;
}

::-moz-focus-inner{
	padding: 0;
	border: 0;
}

:focus{
	outline: 0;
}

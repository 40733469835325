.l-home-intro{
	display: flex;
	flex-direction: column;

	@media (min-width: $md){
		flex-flow: row wrap;
	}

	> *{
		margin-left: $gutter;
		margin-right: $gutter;
	}

	&__btn{
		order: 3;
		padding-bottom: 30px;

		@media (min-width: $md){
			flex: 1 1 $fiveColSpace;
			flex-basis: calc(#{$fiveColSpace} - #{$full-gutter});
			width: calc(#{$fiveColSpace} - #{$full-gutter});
		}
	}

	&__content{
		margin-bottom: 50px;

		@media (min-width: $md){
			flex: 1 1 $sevenColSpace;
			flex-basis: calc(#{$sevenColSpace} - #{$full-gutter});
			width: calc(#{$sevenColSpace} - #{$full-gutter});
			margin-bottom: 0;
			order: 4;
		}
	}

	&__news-title{
		order: 0;
		margin-bottom: 25px;

		@media (min-width: $md){
			margin-bottom: 0;
			order: 0;
		}
	}

	&__news-list{
		margin: 0;
		order: 3;
		margin-bottom: 30px;

		@media (min-width: $md){
			flex: 1 1 100%;
			flex-basis: calc(100% - #{$full-gutter});
			width: 100%;
			margin: 0 (-$gutter) 50px;
			order: 1;
		}

		@media (min-width: $lg){
			margin: 0 0 50px;
		}

		@media (min-width: $xl){
			margin-bottom: 100px;
		}
	}
}

@supports (display: grid){
	.l-home-intro{
		display: grid;
		grid-template-columns: 1fr;
		padding-left: $gutter;
		padding-right: $gutter;

		@media (min-width: $md){
			grid-template-columns: repeat(12, 1fr);
			grid-gap: 50px $full-gutter;
		}

		@media (min-width: $xl){
			grid-gap: 100px $full-gutter;
		}

		> *{
			margin-left: 0;
			margin-right: 0;
		}

		&__btn{
			width: auto;
			grid-row: 4;

			@media (min-width: $md){
				grid-column: 1 / span 5;
				grid-row: 2;
			}
		}

		&__content{
			width: auto;
			grid-row: 1;

			@media (min-width: $md){
				grid-column: 6 / span 7;
				grid-row: 2;
			}
		}

		&__news-title{
			grid-row: 2;

			@media (min-width: $md){
				grid-row: 1;
			}
		}

		&__news-list{
			margin-left: -$gutter;
			margin-right: -$gutter;
			width: calc(100% + #{$full-gutter});
			grid-row: 3;

			@media (min-width: $md){
				grid-column: 1 / -1;
				margin-bottom: 0;
				grid-row: 1;
				margin-left: -$full-gutter;
				margin-right: -$full-gutter;
				width: calc(100% + #{$full-gutter * 2});
			}

			@media (min-width: $xl){
				margin-left: -$gutter;
				margin-right: -$gutter;
				width: calc(100% + #{$full-gutter});
			}
		}
	}
}

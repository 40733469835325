$oneColumnWidth: 1 / $columns * 100%;

.o-quote {
    position: relative;
    padding: 60px 0 120px 15px;
    color: $primary;
    background-color: transparent;

    &:after {
        display: none;
    }

    @media (min-width: $md) {
        padding: 140px 0 120px 15px;
    }

    &__content {
        margin-right: calc(#{$oneColumnWidth} + #{$gutter});
        opacity: 0;
        transform: translateX(-20px);
        transition: opacity $userScrollIn $easeOut 300ms,
            transform $userScrollIn $easeOut 300ms;
    }

    &.scroll-active &__content {
        opacity: 1;
        transform: none;
    }

    &__background {
        position: absolute;
        right: 0;
        top: 0;
        background-color: $grey;
        width: 100vw;
        height: 100%;
        z-index: -1;
    }

    &__icon {
        position: absolute;
        bottom: 0;
        right: calc(#{$oneColumnWidth} + #{$gutter});
        width: 165px;
        height: 115px;
        fill: $primary;
        transform: translateY(25%);

        svg {
            width: 165px;
            height: 115px;
        }
    }

    &__message {
        margin-bottom: 0 !important;
    }

    &__author-details {
        font-size: 1.4rem;
        font-family: $font-family;
        text-transform: uppercase;
        font-weight: 700;
        line-height: $p-line-height;
        margin-bottom: $type-margin-bottom / 2;
    }

    &__message {
        font-family: $header-font-family;
        line-height: 1.2;

        @include css-lock(2.4, 4.8, 560, 1200);
    }
}

.c-news{
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: $md){
        flex-direction: row;
    }

    > *{
        margin-left: $gutter;
        margin-right: $gutter;
    }

    &:not(:last-child){
        margin-bottom: 60px;
    }

    &__date{
        margin-bottom: -22px !important;
        position: relative;
        order: 1;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 2;
        max-width: 650px - 30px;
        width: calc(100% - 30px);
        opacity: 0;
        transform: translate(-20px, 20px);
        transition: opacity $userScrollIn $easeOut 450ms, transform $userScrollIn $easeOut 450ms;

        @media (min-width: $md){
            margin-bottom: 0 !important;
            max-width: none;
            order: 0;
            z-index: initial;
            flex: 1 1 $oneColSpace;
            flex-basis: calc(#{$oneColSpace} - #{$full-gutter});
            width: calc(#{$oneColSpace} - #{$full-gutter});
            transform: translateX(-20px);
        }

        span{
            @media (min-width: $md){
                display: inline-block;
                white-space: nowrap;
                transform: rotate(-90deg) translateX(-45%);
            }
        }
    }

    &:nth-child(even) &__date{
        transform: translate(20px, 20px);

        @media (min-width: $md){
            order: 2;
            align-self: flex-end;
            transform: translateX(20px);

            span{
                transform: translateX(-40%) rotate(90deg) translateX(-45%);
            }
        }
    }

    .scroll-active &__date,
    .scroll-active:nth-child(even) &__date{
        opacity: 1;
        transform: translateY(20px);

        @media (min-width: $md){
            transform: none;
        }
    }

    &:nth-child(2) &__date{
        @media (min-width: $md){
            position: relative;
            color: #fff;

            &:after{
                content: '';
                position: absolute;
                top: -200px;
                left: 50%;
                width: 560px;
                height: 770px;
                background-color: $primary;
                transform: translateX(-50%);
                z-index: -1;
            }
        }
    }

    &__description{
        order: 3;
        max-width: 650px;
        width: 100%;
        z-index: 2;

        @media (min-width: $md){
            max-width: none;
            order: 0;
            flex: 1 1 $fourColSpace;
            flex-basis: calc(#{$fourColSpace} - #{$full-gutter});
            width: calc(#{$fourColSpace} - #{$full-gutter});
        }

        > *{
            text-decoration: none;
            width: 100%;
            max-width: none;
            padding-top: 30px;

            &:after{
                display: none;
            }

            @media (min-width: $md){
                max-width: 333px;
                padding-top: 0;
                width: 290px;

                &:after{
                    display: block;
                }
            }

            .o-square__container{
                position: relative;
                padding: 30px 15px 40px;

                @media (min-width: $md){
                    padding: 40px;
                    position: absolute;
                }
            }
        }

        .o-square__arrow-btn{
            opacity: 0;
            transform: translate(calc(-50% - 30px), 50%);
            transition: opacity $userScrollIn $easeOut 600ms, transform $userScrollIn $easeOut 600ms;
        }

        .scroll-active & .o-square__arrow-btn{
            opacity: 1;
            transform: translate(-50%, 50%);
        }
    }

    &:nth-child(even) &__description{
        @media (min-width: $md){
            order: 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            align-self: flex-end;
        }
    }

    &__image{
        max-width: 650px;
        width: 100%;
        z-index: 2;
        opacity: 0;
        transform: translateX(20px);
        transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn 300ms;

        @media (min-width: $md){
            max-width: none;
            flex: 1 1 $sixColSpace;
            flex-basis: calc(#{$sixColSpace} - #{$full-gutter});
            width: calc(#{$sixColSpace} - #{$full-gutter});
        }

        &-wrap{
            display: block;
            position: relative;
            height: 0;
            padding-bottom: ratio(555, 386);

            > *{
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                max-width: none;
                width: auto;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__title{
        letter-spacing: 0.1em;
    }

    &__insight{
        padding-left: $gutter;
        padding-right: $gutter;
        position: relative;
        text-decoration: none;
        max-width: 650px;
        width: 100%;
        margin: 90px auto;

        @media (min-width: $md){
            max-width: none;
            display: flex;

            > *{
                flex: 1 1 50%;
                width: 50%;
            }
        }

        &:before{
            content: '';
            position: absolute;
            top: -15px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $grey;
            z-index: -1;

            @media (min-width: $md){
                left: 15px;
                top: 0;
                width: calc(100% - 60px);
            }
        }

        &-btn{
            position: absolute;
            bottom: 60px;
            left: -30px;
            opacity: 0;
            transform: translateX(-20px);
            transition: opacity $userScrollIn $easeOut 450ms, transform $userScrollIn $easeOut 450ms;

            @media (min-width: $md){
                left: -15px;
            }
        }

        .scroll-active &-btn{
            opacity: 1;
            transform: none;
        }

        &:hover .o-btn__icon,
        &:focus .o-btn__icon{
            transition-duration: $userActive;
            transform: translateX(40%);
        }

        &:hover .o-btn__icon--left,
        &:hover .o-btn__icon--left{
            transition-duration: $userActive;
            transform: translateX(-40%);
        }

        &:hover .o-btn__icon--up,
        &:hover .o-btn__icon--up{
            transition-duration: $userActive;
            transform: translateY(-40%);
        }

        &:hover .o-btn__icon--down,
        &:hover .o-btn__icon--down{
            transition-duration: $userActive;
            transform: translateY(40%);
        }
    }

    &__content{
        padding: 45px 15px 0;

        @media (min-width: $md){
            padding: 60px 140px 80px 40px;
        }
    }

    &__insight &__image{
        position: relative;
        align-self: flex-end;
        transform: translate(15px + 20px, 15px);

        @media (min-width: $md){
            transform: translateY(30px + 20px);
        }
    }

    &:nth-child(even) &__image{
        transform: translateX(-20px);
    }

    .scroll-active &__image,
    .scroll-active:nth-child(even) &__image{
        opacity: 1;
        transform: none;
    }

    &__insight.scroll-active &__image{
        opacity: 1;
        transform: translate(15px, 15px);

        @media (min-width: $md){
            transform: translateY(30px);
        }
    }
}

@supports (object-fit: cover){
    .c-news{
        &__image{
            &-wrap{
                > *{
                    top: 0;
                    left: 0;
                    width: 100%;
                    transform: none;
                    object-fit: cover;
                }
            }
        }
    }
}

@supports (writing-mode: vertical-lr){
    .c-news{
        @media (min-width: $md){
            &__date{
                width: auto;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;

                span{
                    transform: rotate(180deg);
                    writing-mode: vertical-lr;
                }
            }

            &:nth-child(even) &__date{
                justify-content: flex-start;

                span{
                    transform: none;
                    writing-mode: vertical-lr;
                }
            }

            &:nth-child(2) &__date{
                &:after{
                    content: '';
                    position: absolute;
                    top: -100px;

                }
            }
        }
    }
}

@supports (display: grid){
    .c-news{
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;

        @media (min-width: $md){
            grid-template-columns: repeat(12, 1fr);
            grid-gap: $full-gutter;
            padding-left: $gutter;
            padding-right: $gutter;
        }

        > *{
            margin: 0 auto;

            @media (min-width: $md){
                margin: 0;
            }
        }

        &__date{
            order: 1;

            @media (min-width: $md){
                grid-column: 2 / span 1;
                grid-row: 1;
                width: 0;
            }
        }

        &:nth-child(even) &__date{
            @media (min-width: $md){
                grid-column: 11 / span 1;
            }
        }

        &__description{
            order: 3;

            @media (min-width: $md){
                grid-column: 3 / span 4;
                grid-row: 1;
                width: auto;
            }
        }

        &:nth-child(even) &__description{
            @media (min-width: $md){
                grid-column: 7 / span 4;
            }
        }

        &__image{
            @media (min-width: $md){
                grid-column: 7 / -1;
                grid-row: 1;
                width: auto;
            }
        }

        &:nth-child(even) &__image{
            @media (min-width: $md){
                grid-column: 1 / span 6;
            }
        }

        &__insight{
            display: grid;
            grid-template-columns: 1fr;

            @media (min-width: $md){
                grid-template-columns: 1fr 1fr;
            }

            > *{
                margin: 0;
                width: auto;
            }
        }

        &__insight &__image{
            @media (min-width: $md){
                grid-column: 2 / span 1;
            }
        }
    }
}

.c-team-member{
	display: block;
	position: relative;
	padding-right: 30px;
	max-width: 260px + 30px;
	width: 100%;

	@media (min-width: $sm){
		padding-right: 100px;
		max-width: 260px + 100px;
	}

	&--bottom-left{
		padding-right: 0;
		padding-left: 30px;
		padding-bottom: 70px;
		max-width: 260px + 30px;
	}

	&__image{
		position: relative;
		height: 0;
		padding-bottom: ratio(260, 350);
		overflow: hidden;

		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			height: 100%;
			max-width: none;
			width: auto;
			transform: translate(-50%, -50%);
		}
	}

	&--bottom-left &__image{
		> *{
			transform: translate(-50%, -50%);
		}
	}

	&__square{
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translateY(-50%);
		width: 160px;

		@media (min-width: $sm){
			width: 200px;
		}
	}

	&--bottom-left &__square{
		right: auto;
		left: 0;
		transform: none;
	}

	&--top-right &__square{
		bottom: auto;
		top: 30px;
		transform: none;
	}

	.o-btn{
		background-color: transparent;
	}

	&:hover .o-btn__icon,
	&:focus .o-btn__icon{
		transition-duration: $userActive;
		transform: translateX(40%);
	}

	&:hover .o-btn__icon--left,
	&:hover .o-btn__icon--left{
		transition-duration: $userActive;
		transform: translateX(-40%);
	}

	&:hover .o-btn__icon--up,
	&:hover .o-btn__icon--up{
		transition-duration: $userActive;
		transform: translateY(-40%);
	}

	&:hover .o-btn__icon--down,
	&:hover .o-btn__icon--down{
		transition-duration: $userActive;
		transform: translateY(40%);
	}
}

a.o-btn,
button.o-btn,
a.o-btn.o-btn--white,
button.o-btn.o-btn--white {
    display: inline-flex;
}

.o-btn {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    min-width: 240px;
    height: 60px;
    padding: 15px 30px;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    text-decoration: none;
    color: #fff;
    background-color: $primary;
    transition: background-color $userInactive $easeOut,
        color $userInactive $easeOut, opacity $userActive $easeOut;
    border-radius: 0;
    opacity: 1;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    > * {
        pointer-events: none;
    }

    &:hover,
    &:focus {
        transition: background-color $userActive $easeOut,
            color $userActive $easeOut;
        text-decoration: none;
        color: #fff;
    }

    #{$typegraphyClass} & {
        margin-top: 30px;
    }

    &--square {
        min-width: 0;
        width: 60px;
        padding: 0;
        justify-content: center;

        &:hover,
        &:focus {
            background-color: $primary;
            color: #fff;
        }
    }

    &--white {
        background-color: #fff;
        color: $primary;

        &:hover,
        &:focus {
            background-color: #fff;
            color: $primary;
        }
    }

    &--large {
        width: 145px;
        height: 145px;
    }

    &__icon {
        margin-left: 25px;
        width: 20px;
        height: 20px;
        stroke: currentColor;
        transition: transform $userInactive $easeOut;

        &--flip {
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                transform: translate(-50%, -50%);
                transition: transform $userInactive $easeOut,
                    opacity $userInactive $easeOut;

                &:first-child {
                    transition-delay: $userInactive;
                    opacity: 1;
                }

                &:last-child {
                    opacity: 0;
                    transform: translate(-50%, calc(-50% + 10px));
                }
            }

            .is-open & {
                svg {
                    transition-duration: $userActive;

                    &:first-child {
                        transition-delay: 0ms;
                        opacity: 0;
                        transform: translate(-50%, calc(-50% + 10px));
                    }

                    &:last-child {
                        transition-delay: $userInactive;
                        opacity: 1;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }

    &--square &__icon {
        margin-left: 20px;
        margin-right: 20px;
    }

    &--large &__icon {
        flex: 0 0 65px;
        width: 65px;
        height: 65px;
    }

    &:hover &__icon,
    &:focus &__icon {
        transition-duration: $userActive;
        transform: translateX(40%);
    }

    &:hover &__icon--left,
    &:focus &__icon--left {
        transition-duration: $userActive;
        transform: translateX(-40%);
    }

    &:hover &__icon--up,
    &:focus &__icon--up {
        transition-duration: $userActive;
        transform: translateY(-40%);
    }

    &:hover &__icon--down,
    &:focus &__icon--down {
        transition-duration: $userActive;
        transform: translateY(40%);
    }

    &__arrow-nav {
        display: flex;

        &--large {
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);
        }
    }

    &__arrow-nav:not(&__arrow-nav--large) > *:first-child &__icon {
        margin-left: 10px;
    }

    &__arrow-nav:not(&__arrow-nav--large) > *:last-child &__icon {
        margin-right: 10px;
    }
}

.c-floorplan{
	position: relative;
	background-color: $grey;

	&__viewport{
		position: relative;
		overflow: hidden;
		height: 0;
		padding-bottom: ratio(945, 470);
	}

	&__track{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;

		> *{
			flex: 1 0 100%;
			width: 100%;
		}
	}

	&__arrow{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;

		&:first-child{
			left: 0;
		}

		&:last-child{
			right: 0;
		}
	}

	&__slide{
		display: flex;
		padding: 15px 0;
	}

	&__image{
		position: relative;
		flex: 1 1 auto;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		img{
			position: absolute;
			top: 0;
			left: 50%;
			height: 100%;
			max-width: none;
			width: auto;
			transform: translateX(-50%);
		}
	}
}

.c-tenant-dashboard {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    > * {
        margin-left: $gutter;
        margin-right: $gutter;
        margin-bottom: $full-gutter;
        flex: 1 1 100%;
        flex-basis: calc(100% - #{$full-gutter + 2px});
        width: calc(100% - #{$full-gutter + 2px});

        @media (min-width: $sm) {
            flex: 1 1 50%;
            flex-basis: calc(50% - #{$full-gutter + 2px});
            width: calc(50% - #{$full-gutter + 2px});
        }

        &:not(.o-card--table) {
            order: 1;
        }
    }

    .o-card--table {
        height: 100%;
    }
}

@supports (display: grid) {
    .c-tenant-dashboard {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: $full-gutter;
        padding-left: $gutter;
        padding-right: $gutter;

        @media (min-width: $sm) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: $md) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        > * {
            margin: 0;
            width: 100%;

            &:not(.o-card--table) {
                order: 1;
                grid-row: span 2;

                @media (min-width: $md) {
                    order: 0;
                }
            }
        }
    }
}

.c-gallery{
	overflow: hidden;
	padding-bottom: 50px;

	@media (min-width: $sm){
		padding-bottom: 0;
	}

	&__container{
		width: 100%;
		max-width: $max-width;
		margin: 0 auto;
	}

	&__viewport{
		position: relative;
		width: 100%;

		@media (min-width: $md){
			width: 50%;
		}
	}

	&__track{
		display: flex;

		> *{
			flex: 1 0 100%;
			flex-basis: calc(100% - 30px);
			width: calc(100% - 30px);
		}
	}

	&__slide{
		margin-left: $gutter;
		margin-right: $gutter;
	}

	&__arrows{
		position: absolute;
		bottom: -50px;
		right: 15px;
		z-index: 1;
		padding-left: 15px;
		background-color: #fff;

		@media (min-width: $sm){
			bottom: 0;
			padding-left: 0;
		}
	}

	&__image{
		position: relative;
		height: 100%;
		padding-bottom: ratio(555, 400);
		overflow: hidden;

		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			width: auto;
			height: 100%;
			max-width: none;
			transform: translate(-50%, -50%);
		}
	}

	&__grid{
		&-wrap{
			display: flex;
			flex-wrap: wrap;
			margin-left: -$gutter;
			margin-right: -$gutter;

			> *{
				flex: 1 1 50%;
				flex-basis: calc(50% - #{$full-gutter});
				margin-left: $gutter;
				margin-right: $gutter;

				@include first(2){
					margin-bottom: 30px;
				}
			}
		}
	}
}

@supports (object-fit: cover){
	.c-gallery{
		&__image{
			> *{
				top: 0;
				left: 0;
				height: 100%;
				transform: none;
				object-fit: cover;
			}
		}
	}
}

.o-form-accordion{
	&__container{
		height: 0;
		will-change: height;
		overflow: hidden;
		transition: height $userInactive $easeOut;
	}

	&.active &__container{
		height: auto;
		transition: height $userActive $easeOut;
	}

	&__btn{
		.o-btn__icon{
			transition: transform $userInactive $easeOut;
		}
	}

	&.active &__btn{
		.o-btn__icon{
			transform: rotate(180deg);
			transition-duration: $userActive;
		}
	}
}

.c-newsletter{
	padding-top: 30px;
	padding-bottom: 30px;

	&__content{
		margin-bottom: 30px;
		color: #fff;

		@media (min-width: $sm){
			margin-bottom: 0;
		}

		h1,
		h2,
		h3,
		.h1,
		.h2,
		.h3{
			color: #fff;
		}

		p:last-child{
			margin-bottom: 0;
		}
	}
}

.o-video{
	position: relative;
	overflow: hidden;

	&:after{
		content: '';
		display: block;
		height: 0;
		width: 100%;
		padding-bottom: ratio(945, 470);
	}

	&__player,
	&__player > *,
	&__overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__overlay{
		opacity: 1;
		transition: opacity 1400ms $easeOut;

		&.hidden{
			pointer-events: none;
			opacity: 0;
		}
	}

	&__image{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: auto;
		transform: translate(-50%, -50%);
	}

	&__icon{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		padding: 12px;
		background-color: $primary;
		color: #fff;
		transform: translate(-50%, -50%);
		transition: background-color $userInactive $easeOut, color $userInactive $easeOut;

		svg{
			width: 36px;
			height: 36px;
			stroke: currentColor;
			stroke-width: 2;
		}
	}

	&__overlay:hover &__icon,
	&__overlay:focus &__icon{
		transition: background-color $userActive $easeOut, color $userActive $easeOut;
		background-color: #fff;
		color: $primary;
	}
}

@supports (object-fit: cover){
	.o-video{
		&__image{
			top: 0;
			left: 0;
			height: 100%;
			transform: none;
			object-fit: cover;
		}
	}
}

//====================
// Spacings
//====================

// type vertical space
//====================
$type-margin-top: 		1em;
$type-margin-bottom: 	1.32em;
$p-type-margin-bottom: $type-margin-bottom;
$type-margin-neg-top: 	-0.2em;

// Horizontal Space
//====================
$total-gutter: 30px;
$gutter: $total-gutter / 2;

// Border
//====================
$border-radius: 0;
$border-size: 1px;

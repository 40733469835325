.c-404{
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
	text-align: center;

	@media (min-width: $sm){
		text-align: left;
	}

	@media (min-width: $md){
		padding-bottom: 140px;
	}

	&__title{
		h1{
			margin-bottom: 0;

			@include css-lock(16, 34.2, 320, 1280);
		}
	}

	&__content{
		position: relative;
	}

	&__image{
		position: absolute;
		z-index: -1;
		top: 0;
		right: calc(#{-$threeColSpace} + 50px);
		max-width: 1140px;
		min-width: 560px;
		width: 100%;
		display: none;

		@media (min-width: $sm){
			display: block;
		}
	}

	&__btn{
		@media (min-width: $sm){
			transform: translateX(#{$twoColSpace});
		}
	}
}

// Grid size
//====================

$oneColSpace: 100 / $columns * 1%;
$twoColSpace: 100 / $columns * 2 * 1%;
$threeColSpace: 100 / $columns * 3 * 1%;
$fourColSpace: 100 / $columns * 4 * 1%;
$fiveColSpace: 100 / $columns * 5 * 1%;
$sixColSpace: 100 / $columns * 6 * 1%;
$sevenColSpace: 100 / $columns * 7 * 1%;
$eightColSpace: 100 / $columns * 8 * 1%;
$nineColSpace: 100 / $columns * 9 * 1%;

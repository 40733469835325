.c-story-list{
    overflow: hidden;

    &__container{
        > *{
            &:not(:last-child){
                margin-bottom: 100px;
            }
        }
    }

    &__item{
        > *{
            &:last-child{
                display: none;

                @media (min-width: $md){
                    display: block;
                }
            }
        }
    }

    &__btn{
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut 600ms, transform $userScrollIn $easeOut 600ms;
    }

    .scroll-active &__btn{
        opacity: 1;
        transform: none;
    }

    &__item:nth-child(even){
        color: #fff;

        h1,
        h2,
        h3,
        h4,
        h5,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5{
            color: #fff;
        }
    }

    &__item:nth-child(even) &__content{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 150vw;
            min-width: 470px;
            height: calc(100% + 100px);
            transform: translate(-50%, -50%);
            background-color: $primary;
            z-index: -1;
        }
    }

    &__item:nth-child(even) > *:nth-child(1){
        z-index: 2;
    }

    &__item:nth-child(even) > *:nth-child(2){
        transition: opacity $userScrollIn $easeOut, transform $userScrollIn $easeOut;
    }

    &__item:nth-child(even) > *:nth-child(3){
        transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn $easeOut 300ms;
    }

    &__item:nth-child(even) &__btn{
        padding-top: 50px;
        transition: opacity $userScrollIn $easeOut 450ms, transform $userScrollIn $easeOut 450ms;
    }
}

$propPadding: 75px;
$oneColSpaceInHalf: (2 / $columns) * 100%;

.c-property {
    padding: $propPadding 0;

    @media (min-width: $md) {
        display: flex;
    }

    &__list {
        padding-left: $gutter;
        padding-right: $gutter;

        > * {
            &:not(:last-child) {
                margin-bottom: 30px;

                @media (min-width: $md) {
                    margin-bottom: 100px;
                }
            }
        }
    }

    &__image {
        position: relative;
        height: 0;
        padding-bottom: ratio(600, 322);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;
        transform: translateX(-10%);
        transition: opacity $userScrollIn $easeOut 300ms,
            transform $userScrollIn $easeOut 300ms;

        //&:after{
        //    content: '';
        //    position: absolute;
        //    top: 0;
        //    left: -30px;
        //    width: 100%;
        //    height: 100%;
        //    background-image: inherit;
        //    background-position: center;
        //    background-size: cover;
        //    background-repeat: no-repeat;
        //    transform: translateX(-100%);
        //    display: none;
        //
        //    @media (min-width: $md){
        //        display: block;
        //    }
        //}

        &-container {
            position: relative;

            @media (min-width: $md) {
                flex: 1 1 $sixColSpace;
                flex-basis: calc(#{$sixColSpace} + #{$gutter});
                width: calc(#{$sixColSpace} + #{$gutter});
                align-self: flex-start;
            }

            &:before {
                content: "";
                position: absolute;
                top: -$propPadding;
                right: 140px;
                width: 100%;
                height: 100%;
                background-color: $primary;

                @media (min-width: $md) {
                    //width: 55vw;
                    height: calc(100% + #{$propPadding * 2});
                }

                //content: '';
                //position: absolute;
                //top: -$propPadding;
                //right: calc(#{$oneColSpaceInHalf} + #{$full-gutter});
                //width: 100%;
                //height: 100%;
                //background-color: $primary;
                //
                //@media (min-width: $md){
                //    width: 55vw;
                //    height: calc(100% + #{$propPadding * 2});
                //}
            }
        }
    }

    &:nth-child(2n) &__image {
        transform: translateX(10%);

        &:after {
            left: auto;
            right: -30px;
            transform: translateX(100%);
        }

        &-container {
            order: 1;
        }
    }

    &:nth-child(2n) &__image-container {
        margin-left: -15px;

        &:before {
            right: auto;
            left: 140px;
        }
    }

    .scroll-active &__image,
    .scroll-active:nth-child(2n) &__image {
        opacity: 1;
        transform: none;
    }

    &__content {
        position: relative;
        padding-right: 15px;

        @media (min-width: $md) {
            flex: 1 1 $fiveColSpace;
            width: $fiveColSpace;
            padding-left: $oneColSpace;
        }
    }

    &:nth-child(2n) &__content {
        padding-left: 0;
        padding-right: $oneColSpace;
    }

    &__price {
        padding-top: 25px;
        font-size: 3.6rem;
        color: $primary;
        opacity: 0;
        transform: translateX(20px);
        transition: opacity $userScrollIn $easeOut 450ms,
            transform $userScrollIn $easeOut 450ms;

        @media (min-width: $xs) {
            position: absolute;
            right: 0;
            top: -15px;
            text-align: left;
        }

        @media (min-width: $md) {
            text-align: right;
            top: auto;
            bottom: calc(100% + 15px);
        }

        &-text {
            font-size: 1.4rem;
            font-weight: 500;
        }

        &--title {
            position: relative;
            bottom: auto;
            right: auto;
        }
    }

    &:nth-child(2n) &__price {
        transform: translateX(-20px);

        @media (min-width: $md) {
            right: $twoColSpace;
        }
    }

    .scroll-active &__price,
    .scroll-active:nth-child(2n) &__price {
        opacity: 1;
        transform: none;
    }

    &__description {
        @media (min-width: $xs) {
            margin-top: 25px;
        }

        h1,
        h2,
        h3 {
            color: $primary;
        }

        .o-btn {
            opacity: 0;
            transform: translateY(20px);
            transition: opacity $userScrollIn $easeOut 600ms,
                transform $userScrollIn $easeOut 600ms;
        }

        .scroll-active & .o-btn {
            opacity: 1;
            transform: none;
        }
    }
}

.c-map{
    position: relative;
    padding-bottom: 30px;

    &__container{
        position: relative;
        height: 0;
        padding-bottom: ratio(1140, 617);

        > *{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__square{
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut 300ms, transform $userScrollIn $easeOut 300ms;

        .scroll-active &{
            opacity: 1;
            transform: none;
        }

        @media (min-width: $md){
            position: absolute;
            bottom: 0;
            right: 50px;
        }

        > *{
            width: 100%;
            min-height: 440px;
            max-width: 100%;

            @media (min-width: $md){
                width: 456px;
                min-height: 456px;
            }
        }
    }
}

.c-social{
	position: relative;

	&__btn{
		position: absolute;
		bottom: 0;
		right: 0;
		text-decoration: none;
		text-transform: uppercase;
		color: currentColor;
		font-weight: 700;
		padding: 0 3px;
	}

	&-twitter{
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&__user{
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 35px;
			text-decoration: none;
		}

		&__message{
			line-height: 1.6;

			&,
			p{
				width: 100%;
			}

			p{
				margin-bottom: 15px;
			}
		}

		&__date{
			margin-top: auto;
		}
	}

	&-instagram{
		&__container{
			padding-bottom: 50px;
			margin: 0 auto;
			width: 75%;

			@media (min-width: $xs){
				display: flex;
				margin-left: -$gutter;
				margin-right: 0;
				width: calc(100% + #{$full-gutter});
			}
		}

		&__item{
			flex: 1 1 33.333%;
			flex-basis: calc(33.333% - #{$full-gutter});
			margin-left: $gutter;
			margin-right: $gutter;
		}

		&__image-wrap{
			position: relative;
			height: 0;
			padding-bottom: 100%;
			overflow: hidden;

			> *{
				position: absolute;
				width: auto;
				max-width: none;
				height: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

@supports (object-fit: cover){
	.c-social{
		&-instagram{
			&__image-wrap{
				> *{
					top: 0;
					left: 0;
					width: 100%;
					transform: none;
					object-fit: cover;
				}
			}
		}
	}
}

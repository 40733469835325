.o-back-btn {
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    &__label {
        margin-left: 30px;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    &:not(div):hover .o-btn__icon,
    &:not(div):focus .o-btn__icon {
        transition-duration: $userActive;
        transform: translateX(40%);
    }

    &:not(div):hover .o-btn__icon--left,
    &:not(div):hover .o-btn__icon--left {
        transition-duration: $userActive;
        transform: translateX(-40%);
    }

    &:not(div):hover .o-btn__icon--up,
    &:not(div):hover .o-btn__icon--up {
        transition-duration: $userActive;
        transform: translateY(-40%);
    }

    &:not(div):hover .o-btn__icon--down,
    &:not(div):hover .o-btn__icon--down {
        transition-duration: $userActive;
        transform: translateY(40%);
    }
}

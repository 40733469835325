.h-image{
	&--large,
	&--tall,
	&--tall-large,
	&--skinny{
		position: relative;
		height: 0;
		overflow: hidden;
	}

	&--large{
		padding-bottom: ratio(945, 470);

		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			height: 100%;
			max-width: none;
			width: auto;
			transform: translate(-50%, -50%);
		}
	}

	&--tall{
        padding-bottom: 100%;

        @media (min-width: $xs){
            padding-bottom: ratio(16, 13);
        }

		@media (min-width: $md){
			padding-bottom: ratio(420, 624);
		}
	}

	&--tall-large{
		padding-bottom: 100%;

		@media (min-width: $md){
			padding-bottom: ratio(535, 820);
		}
	}

	&--skinny{
		padding-bottom: 100%;

		@media (min-width: $md){
			padding-bottom: ratio(400, 680);
		}
	}

	&--tall-large,
	&--tall,
	&--skinny{
		> *{
			position: absolute;
			top: 50%;
			left: 50%;
			height: auto;
			width: 100%;
			transform: translate(-50%, -50%);
		}
	}
}

@supports (object-fit: cover){
	.h-image{
		&--large,
		&--tall,
		&--tall-large,
		&--skinny{
			> *{
				top: 0;
				left: 0;
				width: 100%;
				transform: none;
				object-fit: cover;
			}
		}
	}
}

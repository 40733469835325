.c-header {
    position: relative;

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
        padding: 40px $gutter;

        @media (min-width: $md) {
            padding: 90px $gutter 105px;
        }
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 90px + 40px + 70px + 24px + 90px;
        background-color: $primary;
        z-index: $nav-index;
        transform: translateY(-100%);
        transition: transform $userInactive $easeOut;
        display: none;

        @media (min-width: $md) {
            display: block;
        }
    }

    &.is-open &__overlay {
        transform: none;
        transition-duration: $userActive;
    }

    &__closearea {
        position: absolute;
        top: 100%;
        left: 0;
        height: calc(100vh - (90px + 40px + 70px + 24px + 90px));
        width: 100%;
        z-index: $nav-index;
        pointer-events: none;
        transform: translateX(-100%);
        transition: transform 1ms $userInactive $easeOut;
        display: none;

        @media (min-width: $md) {
            display: block;
        }
    }

    &.is-open &__closearea {
        pointer-events: auto;
        transform: none;
        transition-delay: $userActive;
    }

    &__btn {
        position: absolute;
        top: calc(100% - 65px);
        right: 15px;
        padding: 5px 2px;
        color: #fff;
        z-index: $nav-index + 1;
        transform: translateY(-20px);
        opacity: 0;
        pointer-events: none;
        transition: transform $userInactive $easeOut,
            opacity $userInactive $easeOut;
        display: none;

        @media (min-width: $md) {
            display: block;
        }

        @media (min-width: $lg) {
            display: none;
        }
    }

    &.is-open &__btn {
        transition: transform $userActive 125ms $easeOut,
            opacity $userActive 125ms $easeOut;
        opacity: 1;
        transform: none;
        pointer-events: auto;
    }

    &__logo {
        position: relative;
        height: 50px;
        flex: 1 1 40%;
        display: block;
        z-index: $nav-index + 3;

        a {
            display: block;

            &,
            > * {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 310px;
                width: calc(100vw - #{60px + 30px + 30px});
                height: 100%;

                @media (min-width: $xs) {
                    width: 310px;
                }
            }

            > * {
                @media (min-width: $xs) {
                    width: auto;
                    max-width: 310px;
                }
            }
        }

        svg {
            fill: $primary;
            transition: fill $userInactive $easeOut;
        }
    }

    &--tenant &__logo {
        flex: 1 1 0;
    }

    &.is-open &__logo svg {
        fill: #fff;
    }

    &__nav {
        flex: 0 0 100%;
        position: relative;
        z-index: $nav-index + 1;
        color: $primary;
        display: flex;
        justify-content: flex-end;

        @media (min-width: $md) {
            flex: 1 1 auto;
        }

        .overview {
            @media (min-width: $md) {
                display: none;
            }
        }

        > ul {
            position: absolute;
            top: 100px;
            right: -15px;
            width: calc(100% + #{$full-gutter});
            padding: 0 15px;
            pointer-events: none;

            @media (min-width: $md) {
                pointer-events: auto;
            }

            > * {
                opacity: 0;
                transition: opacity ($userInactive - 150ms) $easeOut;

                @media (min-width: $md) {
                    opacity: 1;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: -150px;
                left: 0;
                width: 100%;
                height: calc(100% + 200px);
                background-color: $primary;
                transform: translateY(-100%);
                transition: transform $userInactive $easeOut;
                opacity: 1;

                @media (min-width: $md) {
                    display: none;
                }
            }

            @media (min-width: $md) {
                position: relative;
                top: auto;
                right: auto;
                width: auto;
                padding: 0;
            }

            li {
                margin-bottom: 15px;

                @media (min-width: $md) {
                    margin-bottom: 0;
                }
            }
        }

        ul {
            @media (min-width: $md) {
                display: inline-flex;
            }
        }

        ul ul {
            flex-wrap: wrap;
            opacity: 0;
            pointer-events: none;
            transform: translateY(-20px);
            transition: transform $userInactive $easeOut,
                opacity $userInactive $easeOut;
            width: 100%;

            li:first-child {
                margin-top: 10px;

                @media (min-width: $md) {
                    margin-top: 0;
                }
            }

            @media (min-width: $md) {
                position: absolute;
                top: calc(100% + 45px);
                left: 0;
                padding-right: 60px;
            }
        }

        li + li {
            @media (min-width: $md) {
                margin-left: 30px;
            }
        }

        ul ul li {
            margin-bottom: 10px;

            &:not(:last-child) {
                margin-right: 25px;
            }
        }

        ul a,
        ul button {
            position: relative;
            text-transform: uppercase;
            font-weight: 700;
            line-height: 1;
            white-space: nowrap;
            text-decoration: none;
            display: block;
            padding: 12px 0;
            color: currentColor;
            width: 100%;
            text-align: left;

            @media (min-width: $md) {
                width: auto;
            }

            &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: currentColor;
                opacity: 0;
                transition: opacity $userInactive $easeOut;
            }

            &:hover:after,
            &:focus:after {
                transition-duration: $userActive;
                opacity: 1;
            }
        }

        li.is-active button:after {
            transition-duration: $userActive;
            opacity: 1;
        }

        ul ul a {
            text-transform: none;
            font-weight: 400;
            padding: 10px 5px;
            position: relative;
            left: -5px;
            z-index: 2;
        }

        &-mobile-btn {
            z-index: $nav-index + 1;
            color: $black;

            @media (min-width: $md) {
                display: none;
            }
        }

        > ul {
            ul {
                height: 0;
                // overflow: hidden;
            }

            li.is-active ul {
                height: auto;
            }
        }
    }

    &--tenant &__nav {
        align-items: center;

        > * + * {
            margin-left: 40px;
        }
    }

    &__nav-btn.o-hambuger {
        width: 60px;
    }

    &.is-open &__nav-mobile-btn {
        color: #fff;
    }

    &.is-open &__nav {
        > ul {
            pointer-events: auto;

            > * {
                transition: opacity $userActive $userInactive $easeOut;
                opacity: 1;
            }

            &:before {
                transition-duration: $userActive;
                transform: none;
            }
        }
    }

    &.is-open &__nav {
        color: #fff;

        li.is-active ul {
            opacity: 1;
            pointer-events: auto;
            transform: none;
            transition: transform $userActive 125ms $easeOut,
                opacity $userActive 125ms $easeOut;
        }
    }

    &--tenant &__subnav {
        flex-wrap: wrap;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-20px);
        transition: transform $userInactive $easeOut,
            opacity $userInactive $easeOut;
        margin: 0;

        li:first-child {
            margin-top: 10px;

            @media (min-width: $md) {
                margin-top: 0;
            }
        }

        @media (min-width: $md) {
            position: absolute;
            justify-content: flex-end;
            top: calc(100% + 45px);
            left: 0;
            width: 100%;
        }
    }

    &.is-open &__subnav {
        opacity: 1;
        pointer-events: auto;
        transform: none;
        transition: transform $userActive 125ms $easeOut,
            opacity $userActive 125ms $easeOut;
    }

    &__menu-btn {
        color: #fff;
        z-index: 1;
        background-color: $primary;
        width: 45px;
        height: 45px;
    }

    &__settings {
        display: none;
        color: $primary;
        transition: color $userInactive $easeOut;

        @media (min-width: $md) {
            display: block;
        }

        &,
        svg {
            width: 28px;
            height: 28px;
        }
    }

    &.is-open &__settings {
        color: #fff;
        transition-duration: $userActive;
    }

    &__mobile-links {
        @media (min-width: $md) {
            display: none;
        }
    }

    &__tenant-portal {
        position: absolute;
        top: 0;
        right: 95px;
        display: none;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        background-color: $grey;
        padding: 15px 30px;
        transition: color $userInactive $easeOut;
        z-index: $nav-index + 1;

        @media (min-width: $md) {
            display: block;
        }
    }

    &.is-open &__tenant-portal {
        color: $primary;
        transition-duration: $userActive;
    }
}

.c-tall-slider{
    position: relative;
    overflow: hidden;

    &__container{
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
    }

    &__viewport{
        position: relative;
    }

    &__track{
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $md){
            flex-wrap: nowrap;
        }

        > *{
            margin-left: $gutter;
            margin-right: $gutter;
            flex: 1 1 250px;
            margin-bottom: 30px;

            @media (min-width: $md){
                flex: 1 0 33.333%;
                flex-basis: calc(33.333% - #{$full-gutter});
                width: calc(33.333% - #{$full-gutter});
            }
        }
    }

    &__slide{
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__image{
        position: relative;
        height: 0;
        padding-bottom: ratio(300, 200);
        width: 100%;

        @media (min-width: $md){
            padding-bottom: ratio(290, 390);
        }

        > *{
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;
            max-width: none;
            width: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__slide:nth-child(odd):last-child &__image{
        @media (min-width: $xs){
            padding-bottom: ratio(300, 100);
        }

        @media (min-width: $md){
            padding-bottom: ratio(290, 390);
        }
    }

    &__text{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 50px;
        text-align: center;
        background-color: $primary;
        color: #fff;

        h1,
        h2,
        h3,
        .h1,
        .h2,
        .h3{
            color: #fff;
        }

        p:last-child{
            margin-bottom: 0;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        background-color: $grey;
        text-align: center;
        padding: 25px 15px 15px;
        flex: 1 1 auto;

        @media (min-width: $md){
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 60px 25px 25px;
            transform: translateY(100%);
            transition: transform $userActive $easeOut;
        }

        > *{
            &:first-child{
                margin-bottom: auto;
            }
        }

        .o-btn{
            &:not(:last-child){
                margin-bottom: 15px;
            }

            @media (min-width: $md){
                &:not(:last-child){
                    margin-bottom: 0;
                }

                margin-top: 25px;
            }
        }
    }

    &__slide:hover &__content,
    &__slide:focus &__content{
        transition-duration: 550ms;
        transform: none;
    }

    &__arrow{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        @media (max-width: $sm-max){
            display: none !important;
        }

        &:first-child{
            left: 0;
        }

        &:last-child{
            right: 0;
        }
    }
}

@supports (object-fit: cover){
    .c-tall-slider__image{
        > *{
            top: 0;
            left: 0;
            width: 100%;
            transform: none;
            object-fit: cover;
        }
    }
}

$wrapWidth: 4 / $columns * 100%;

.c-story {
    position: relative;
    padding-bottom: 30px;
    color: #fff;

    @media (min-width: $sm) {
        padding-top: 180px;
        padding-bottom: 80px;
    }

    &__background {
        position: absolute;
        top: 180px;
        left: 0;
        width: 100%;
        height: calc(100% - 180px);
        background-color: $primary;
        z-index: -1;
        transform: translateY(20px);
        opacity: 0;
        transition: opacity $userScrollIn $easeOut,
            transform $userScrollIn $easeOut;
    }

    &.scroll-active &__background {
        opacity: 1;
        transform: none;
    }

    &__container {
        display: flex;
        flex-direction: column;

        @media (min-width: $sm) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__content {
        padding-top: 30px;
        transform: translateY(20px);
        opacity: 0;
        transition: opacity $userScrollIn $easeOut 300ms,
            transform $userScrollIn $easeOut 300ms;

        @media (min-width: $sm) {
            padding-top: 75px;
        }

        h1,
        h2,
        h3,
        .h1,
        .h2,
        .h3 {
            color: #fff;
        }

        &-wrap {
            $wrapWidth: 7 / $columns * 100%;

            position: relative;
            flex: 1 1 100%;
            flex-basis: calc(100% - #{$full-gutter});
            margin-left: $gutter;
            margin-right: $full-gutter;
            order: 1;

            @media (min-width: $sm) {
                order: 0;
                flex: 0 0 $wrapWidth;
                flex-basis: calc(#{$wrapWidth} - #{$full-gutter + $gutter});
            }
        }
    }

    &.scroll-active &__content {
        opacity: 1;
        transform: none;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 165px;
        height: 115px;
        fill: $grey;
        transform: translateY(calc(-75% - 20px));
        display: none;
        opacity: 0;
        transition: opacity $userScrollIn $easeOut 450ms,
            transform $userScrollIn $easeOut 450ms;

        @media (min-width: $sm) {
            display: block;
        }

        svg {
            width: 165px;
            height: 115px;
        }
    }

    &.scroll-active &__icon {
        transform: translateY(-75%);
        opacity: 1;
    }

    &__image {
        flex: 0 0 $wrapWidth;
        transform: translateX(20px);
        opacity: 0;
        transition: opacity $userScrollIn $easeOut 450ms,
            transform $userScrollIn $easeOut 450ms;

        @media (min-width: $sm) {
            margin-right: $gutter;
        }

        &-wrap {
            display: block;
            position: relative;
            height: 0;
            overflow: hidden;
            padding-bottom: ratio(390, 450);

            @media (min-width: $sm) {
                margin-top: -130px;
                transform: translateY(-180px + 130px);
            }

            > * {
                position: absolute;
                top: 50%;
                left: 50%;
                width: auto;
                max-width: none;
                height: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.scroll-active &__image {
        opacity: 1;
        transform: none;
    }

    &__btn {
        margin-top: 30px;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $userScrollIn $easeOut 600ms,
            transform $userScrollIn $easeOut 600ms;
    }

    &.scroll-active &__btn {
        opacity: 1;
        transform: none;
    }
}

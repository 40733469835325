.c-news-listing{
    position: relative;

    @media (min-width: $md) and (max-width: $lg-max){
        padding-top: 25px;
    }

    &--footer{
        padding-top: 30px;

        @media (min-width: $md){
            padding-top: 90px;
        }
    }

    &__title{
        color: $primary;
        transform: translateX(-100%);
        transition: transform $userScrollIn $easeOut 300ms;

        @media (min-width: $md){
            position: absolute;
            top: -20px;
            left: 0;
            color: #fff;
        }

        @media (min-width: $xl){
            left: auto;
            top: 0;
            right: calc(100% + 45px);
            height: 100%;
            padding-left: 90px;
        }

        span{
            font-weight: 700;
            text-transform: uppercase;
            display: inline-block;
            white-space: nowrap;
            opacity: 0;
            transition: opacity $userScrollIn $easeOut 450ms;

            @media (min-width: $xl){
                transform: rotate(-90deg) translate(-45%, 40px);
            }
        }

        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 90px;
            width: 50vw;
            height: 75vw;
            background-color: $primary;
            transform: translateY(-50%);
            z-index: -1;
            display: none;

            @media (min-width: $md){
                display: block;
                left: calc(-10vw - 70px);
                top: 100px;
                width: $max-width / 2;
                height: $max-width / 1.5;
            }

            @media (min-width: $xl){
                left: 90px;
            }
        }

        &.scroll-active{
            transform: none;

            span{
                opacity: 1;
            }
        }
    }

    &--footer &__title{
        top: 0;
        margin-bottom: 30px;
        padding-left: $gutter;
        padding-right: $gutter;

        @media (min-width: $md){
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: $xl){
            top: 90px;
        }

        &:before{
            top: -95px;
            transform: none;
        }
    }

    &__list{
        @media (min-width: $md){
            display: flex;

            > *{
                flex: 1 1 33.333%;
                flex-basis: calc(33.333% - #{$full-gutter});
                margin-left: $gutter;
                margin-right: $gutter;
            }
        }
    }

    &__item{
        position: relative;
        display: block;
        text-decoration: none;

        @media (min-width: $md){
            display: flex;

            > *{
                flex: 1 0 50%;
                flex-basis: calc(50% - #{$gutter});

                &:first-child{
                    margin-right: 15px;
                }

                &:last-child{
                    margin-left: 15px;
                }
            }
        }

        &:not(:last-child){
            margin-bottom: 60px;

            @media (min-width: $md){
                margin-bottom: 0;
            }
        }

        &:hover,
        &:focus{
            color: currentColor;
        }

        &:first-child{
            color: #fff;

            h1,
            h2,
            h3,
            .h1,
            .h2,
            .h3{
                color: #fff;
            }
        }

        &:last-child{
            @media (max-width: $sm-max){
                color: #fff;

                h1,
                h2,
                h3,
                .h1,
                .h2,
                .h3{
                    color: #fff;
                }
            }
        }

        &:first-child:before,
        &:last-child:before{
            content: '';
            position: absolute;
            left: 0;
            top: 60px;
            width: 100%;
            height: calc(100% - 40px);
            background-color: $primary;
            z-index: -1;

            @media (min-width: $md){
                display: none;
            }
        }

        &:last-child:before{
            height: calc(100% + 60px);
        }

        &-title{
            span{
                position: relative;
                display: inline-block;
                outline: none;
                white-space: nowrap;
                text-decoration: none;
                color: currentColor;
                overflow: hidden;
                vertical-align: bottom;

                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -3px;
                    bottom: 0;
                    right: -3px;
                    background-color: $primary;
                    z-index: -1;
                    transform: translateY(100%);
                    transition: transform $userInactive $easeOut;
                    backface-visibility: hidden;
                    pointer-events: none;
                }
            }
        }

        &:first-child &-title span:before{
            background-color: $grey;
        }

        &:hover &-title span,
        &:focus &-title span{
            color: $grey;
        }

        &:first-child:hover &-title span,
        &:first-child:focus &-title span{
            color: $primary;
        }

        &:hover &-title span:before,
        &:focus &-title span:before{
            transition-duration: $userActive;
            transform: none;
        }

        &:last-child &-title span:before{
            @media (max-width: $sm-max){
                background-color: $grey;
            }
        }

        &:last-child:hover &-title span,
        &:last-child:focus &-title span{
            @media (max-width: $sm-max){
                color: $primary;
            }
        }
    }

    &__image{
        width: calc(100% - #{$gutter});

        @media (min-width: $md){
            width: auto;
        }

        &-wrap{
            position: relative;
            height: 0;
            padding-bottom: ratio(610, 320);

            @media (min-width: $md){
                padding-bottom: 100%;
            }

            > *{
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__content{
        padding-left: $gutter;
        padding-right: $gutter;
        padding-top: 25px;

        @media (min-width: $md){
            padding: 0;
        }

        p:last-child{
            margin-bottom: 0;
        }
    }

    &__btn{
        &-container{
            margin-top: 30px;
            margin-bottom: 70px;
            padding-left: $gutter;
            padding-right: $gutter;

            @media (min-width: $md){
                margin-top: 90px;
                display: flex;
                justify-content: space-between;
            }

            > *{
                &:first-child{
                    margin-bottom: 45px;

                    @media (min-width: $md){
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@supports (object-fit: cover){
    .c-news-listing{
        &__image{
            &-wrap{
                > *{
                    top: 0;
                    left: 0;
                    height: 100%;
                    transform: none;
                    object-fit: cover;
                }
            }
        }
    }
}

@supports (display: grid){
    .c-news-listing{
        @media (min-width: $md) and (max-width: $lg-max){
            padding-top: 45px;
        }

        &__title{
            @media (min-width: $md){
                top: -45px;
                left: 15px;
            }

            @media (min-width: $xl){
                left: auto;
                top: 0;
            }
        }
    }
}

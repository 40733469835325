body{
	font-family: $font-family;
	color: $text-color;
	background-color: $body-background-color;
	margin: 0;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-kerning: normal;
	overflow-x: hidden;
	font-weight: $font-weight;
	font-size: unit(strip-unit($base-font-size), em);
	line-height: $p-line-height;
}
